// Core Modules
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// Shared Services
import { SharedService } from '../shared.service';

export enum TRUNCATION_SETTINGS {
  TITLE = 100,
  DESCRIPTION = 180
}
@Component({
  selector: 'mmb-web-app-card-summary',
  templateUrl: './card-summary.component.html',
  styleUrls: ['./card-summary.component.scss']
})
export class CardSummaryComponent implements OnInit {
  @Input() title: string;
  @Input() slug: string;
  @Input() isDetailsView: boolean;
  @Input() summary: string;
  @Input() description: string;
  @Input() authorName: string;
  @Input() authorImage: string;
  @Input() createdOnDate: Date;
  @Input() authorProfessionalTitle: string;
  @Input() coverImage: string;
  @Input() location: string;
  @Input() type: string;
  @Input() totalComments: number;
  @Input() endDate: string;
  @Input() country: string;
  @Input() cityCustom: string;
  @Input() city: string;
  @Input() isSaved: boolean;
  @Output() addToFavorites: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeFromFavorites: EventEmitter<any> = new EventEmitter<any>();

  titleTruncation = TRUNCATION_SETTINGS.TITLE;
  descriptionTruncation = TRUNCATION_SETTINGS.DESCRIPTION;

  /**
   * Constructor
   */
  constructor(private sharedService: SharedService) { }

  /**
   * Lifecycle hooks
   */
  ngOnInit() {
  }

  /**
   * Gets time left
   * @param endDate end date
   */
  getTimeLeft() {
    let timeLeft = this.sharedService.getTimeLeft(this.endDate);
    return timeLeft;
  }

  onAddToFavorites(ev) {
    this.addToFavorites.emit();
    ev.stopPropagation();
  }

  onRemoveFromFavorites(ev) {
    this.removeFromFavorites.emit();
    ev.stopPropagation();
  }

  /**
   * Gets location
   */
  get getLocation() {
    return this.sharedService.getLocation(this.country, this.city, this.cityCustom);
  }
}
