import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'projects/mmb-web-app/src/environments/environment';
import { FeedService } from '../../feed/feed.service';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'mmb-web-app-forum-answer-item-card',
  templateUrl: './forum-answer-item-card.component.html',
  styleUrls: ['./forum-answer-item-card.component.scss']
})
export class ForumAnswerItemComponent implements OnInit {
  @Input() answer: any;
  @Input() isAuthenticated: boolean;
  @Input() user: any;
  @Input() isBestAnswer: boolean;
  @Input() question: string;

  constructor(private modalService: NgbModal, private feedService: FeedService) { }

  ngOnInit() {
  }

  onAddComment(comment: string) {
    if (this.isAuthenticated) {
      let newComment = {
        answer: this.answer.id,
        comment: comment
      };

      this.feedService.addNewComment(`${environment.domain}${environment.api.forum.answerComment}`, newComment).subscribe((ev: any) => {
        let user = JSON.parse(localStorage.getItem('user'));
        ev.user = user;
        ev.user.name = `${user.first_name} ${user.last_name}`;
        if (!this.answer.comments) {
          this.answer.comments = [];
        }
        this.answer.comments.unshift(ev);
        this.answer.comments_total++;
      })
    } else {
      this._openRegisterModal();
    }
  }

  onCommentUpvote(comment: any) {
    this.feedService.onCommentUpvoteClicked(`${environment.domain}${environment.api.forum.answerCommentVote}`, true, comment);
  }

  onCommentDownvote(comment: any) {
    this.feedService.onCommentDownvoteClicked(`${environment.domain}${environment.api.forum.answerCommentVote}`, false, comment);
  }

  private _openRegisterModal() {
    const modalRef = this.modalService.open(RegisterComponent, {
      centered: true,
      size: 'md'
    });
    modalRef.componentInstance.isOpenedInModal = true;
    modalRef.componentInstance.completed.subscribe(() => {
      modalRef.dismiss();
    });
  }
}
