import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { Filter } from '../types/filter.model';

@Component({
  selector: 'mmb-web-app-mobile-filter-listing',
  templateUrl: './mobile-filter-listing.component.html',
  styleUrls: ['./mobile-filter-listing.component.scss']
})
export class MobileFilterListingComponent implements OnInit {
  @Output() applied: EventEmitter<any> = new EventEmitter<any>();
  @Input() isOpenedInModal: boolean;
  @Input() title: string;
  @Input() filters: BehaviorSubject<Filter> = new BehaviorSubject<Filter>(null);
  @Input() entityType: string;

  constructor(private activeModal: NgbActiveModal) { }
  ngOnInit() {
  }

  onClose() {
    this.activeModal.close();
  }

  onFiltersUpdated(ev) {
    this.filters.next(ev);
  }

  onApply() {
    this.applied.emit(this.filters.value);
  }
}
