import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 * Promotion banner component
 */
@Component({
  selector: 'mmb-web-app-promotion-banner',
  templateUrl: './promotion-banner.component.html',
  styleUrls: ['./promotion-banner.component.scss']
})
export class PromotionBannerComponent implements OnInit {
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() image: string;
  @Input() promoTitle: string;
  @Input() promoDescription: string;
  @Input() buttonText: string;

  constructor() { }

  ngOnInit() {
  }

  onButtonClicked() {
    this.buttonClicked.emit();
  }
}
