export const OPPORTUNITY_TYPES = [ {
    value: '1',
    viewValue: 'Co-Founder',
    icon: 'ri-group-line',
    class: 'co-founder'
}, {
    value: '2',
    viewValue: 'Partnership',
    icon: 'ri-service-line',
    class: 'partnership'
}, {
    value: '3',
    viewValue: 'Expertise / Advice',
    icon: 'ri-microscope-line',
    class: 'expert-advice'
}, {
    value: '4',
    viewValue: 'Mentor',
    icon: 'ri-plant-line',
    class: 'mentor'
}, {
    value: '5',
    viewValue: 'Connection',
    icon: 'ri-bubble-chart-line',
    class: 'connection'
},{
    value: '6',
    viewValue: 'Volunteer',
    icon: 'ri-hand-heart-line',
    class: 'volunteer'
},  {
    value: '7',
    viewValue: 'Investment',
    icon: 'ri-pie-chart-line',
    class: 'investment'
}, {
    value: '8',
    viewValue: 'Other',
    icon: 'ri-lightbulb-line',
    class: 'other'
}];