import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { map, shareReplay, tap } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../src/environments/environment';
import { User } from './types/user.model';
import { Experience } from './types/helpers/experience.model';
import { Education } from './types/helpers/education.model';
import { Language } from './types/helpers/language.model';
import { Skill } from './types/helpers/skill.model';
import { UserDetails } from './types/helpers/user-details.model';
import { ProfileTracker, PROFILE_TRACKER_STAGE_STATUS } from './types/profile-tracker.model';
import { PROFILE_STAGES } from './types/profile-stages.model';
import { UserProfile } from '../careers/types/user-profile.model';
import { FILE_UPLOAD_SOURCE, PROFILE_UPLOAD_FILE_STATUS } from './types/profile-action-types.enum';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    constructor(private http: HttpClient, private toastr: ToastrService) { }

    docsUploadStepData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    selectedCareerDetails: User;

    user: User;
    profileTracker$: BehaviorSubject<ProfileTracker[]> = new BehaviorSubject<ProfileTracker[]>([]);
    userProfile$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    user$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    /**
     * Loads user's profile
     * @param userId get profile by user id
     */
    getUserProfile(): Observable<User> {
        const url = `${environment.domain}${environment.api.user.profile}?response_type=full`;
        return this.http.get(url).pipe(
            shareReplay(1),
            tap((response) => {
                if (response && (<any>response).results) {
                    this.userProfile$.next((<any>response).results[0]);
                }
            }),
            map((response: HttpResponse<any>): User => {
                return this.user = (<any>response).results[0];
            })
        );
    }

    /**
    * Loads user's basic profile
    * 
    */
    getUser(): Observable<User> {
        const url = `${environment.domain}${environment.api.auth.basicAuthUser}`;
        return this.http.get(url).pipe(
            map((response: HttpResponse<any>): User => {
                if (response) {
                    this.user$.next((<any>response));
                }

                return (<any>response);
            })
        );
    }

    /**
     * Get user profile tracker information
     */
    getUserProfileTracker() {
        const url = `${environment.domain}${environment.api.user.profileTracker}`;
        return this.http.get(url);
    }

    /**
     * Checks if stage exists in profile tracker already
     * @param stage Stage number
     */
    isStepExistsInProfileTracker(stage: number) {
        let profileTracker = this.profileTracker$.value;

        if (profileTracker) {
            // check if stage exists in profile tracker
            for (let item of profileTracker) {
                if (item.stage === stage) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Create profile tracker for stage
     * @param stage stage number
     */
    createProfileTrackerForStage(stage: number) {
        // Send information to user tracker that page is loaded
        this.setUserProfileTracker(stage).subscribe(() => {
            // get updated user profile tracker information
            this.getUserProfileTracker().subscribe((responese: any) => {
                this.profileTracker$.next(responese.results);
            })
        }, (err) => {
            this.toastr.error(`Unable to create profile tracker for stage ${stage}`, 'Error');
        });
    }

    /**
     * Sets user profile tracker
     */
    setUserProfileTracker(stageId?: number, status: number = PROFILE_TRACKER_STAGE_STATUS.PENDING): Observable<any> {
        const url = `${environment.domain}${environment.api.user.profileTracker}`;

        if (stageId === PROFILE_STAGES.USER_SIGNUP) {
            // reset the profile tracker
            this.profileTracker$.next(null);
        }
        let profileTracker = this.profileTracker$.value;
        // POST of profile tracker does not exist
        if (!profileTracker || profileTracker.length === 0) {
            return this.http.post(url, { stage: PROFILE_STAGES.USER_SIGNUP, status: PROFILE_TRACKER_STAGE_STATUS.COMPLETED });
        } else {
            // check if profile tracker contains value for the stage
            let stageTrackerRecord = this._getStageTrackerRecord(profileTracker, stageId);

            if (stageTrackerRecord) {
                // send PUT request
                return this.http.put(`${url}${stageTrackerRecord.uuid}/`, { stage: stageTrackerRecord.stage, status: status });
            } else {
                // send POST request
                return this.http.post(url, { stage: stageId });
            }
        }
    }

    /**
     * Creates user's profile first/last name
     */
    createUserName(first_name: string, last_name: string) {
        let url = `${environment.domain}${environment.api.user.user}`;

        return this.http.post(url, {
            'first_name': first_name,
            'last_name': last_name,
            // 'username': this.user.username
        }).pipe(
            map((response: HttpResponse<any>): UserDetails => {
                return <any>response;
            })
        );
    }

    updateUserPassword(password: string) {
        let url = `${environment.domain}${environment.api.auth.changePassword}`;

        return this.http.post(url, {
            'new_password1': password,
            'new_password2': password,
        });
    }

    getUserNotificationGroup() {
        let url = `${environment.domain}${environment.api.user.messaging}/?limit=50&offset=0`;

        return this.http.get(url).pipe(
            map((response: any): any => {
                return <any>response.results;
            })
        );
    }

    addUserNotificationGroup(groupId: number) {
        let url = `${environment.domain}${environment.api.user.messaging}/`;

        return this.http.post(url, {
            'group_type': groupId,
            channel_type: 1
        });
    }

    deleteUserNotificationGroup(uuid: string) {
        let url = `${environment.domain}${environment.api.user.messaging}/${uuid}/`;
        return this.http.delete(url);
    }

    /**
     * Updates user's profile first/last name
     */
    updateUserName(first_name: string, last_name: string) {
        let url = `${environment.domain}${environment.api.user.user}${this.user.username}`;

        return this.http.put(url, {
            'first_name': first_name,
            'last_name': last_name,
            'username': this.user.username
        }).pipe(
            map((response: HttpResponse<any>): UserDetails => {
                return <any>response;
            })
        );
    }

    /**
     * Updates user's profile first/last name
     */
    updateUserNameByUuid(first_name: string, last_name: string, uuid: string) {
        let url = `${environment.domain}${environment.api.user.user}${uuid}/`;

        return this.http.put(url, {
            'first_name': first_name,
            'last_name': last_name,
        }).pipe(
            map((response: HttpResponse<any>): UserDetails => {
                return <any>response;
            })
        );
    }

    /**
     * Updates user's profile basic info
     */

    updateUserBasicInfo(payload) {
        let url = `${environment.domain}${environment.api.user.user}${this.userProfile$.value.uuid}/`;

        return this.http.put(url, payload).pipe(
            map((response: HttpResponse<any>): UserDetails => {
                return <any>response;
            })
        );
    }

    /**
     * Creates user's profile credentials
     */
    createUserCredentials(professional_title: string) {
        let url = `${environment.domain}${environment.api.career.credentials}`;

        return this.http.post(url, {
            'professional_title': professional_title,
        }).pipe(
            map((response: HttpResponse<any>): UserDetails => {
                return <any>response;
            })
        );
    }
    /**
     * Updates user's profile credentials
     */
    updateUserCredentials(credentials: string) {
        let url = `${environment.domain}${environment.api.career.credentials}${this.user.credentials.uuid}`;

        return this.http.put(url, credentials).pipe(
            map((response: HttpResponse<any>): UserDetails => {
                return <any>response;
            })
        );
    }

    /**
     * Creates user's profile details
     */
    createUserDetails(country_signup: number, city_signup: number) {
        let url = `${environment.domain}${environment.api.career.details}`;

        return this.http.post(url, {
            'country_signup': country_signup,
            'city_signup': city_signup,
        }).pipe(
            map((response: HttpResponse<any>): UserDetails => {
                return <any>response;
            })
        );
    }
    /**
     * Updates user's profile details
     */
    updateUserDetails(country_signup: number, city_signup: number) {
        let url = `${environment.domain}${environment.api.user.user}${this.user.username}`;

        return this.http.put(url, {
            'country_signup': country_signup,
            'city_signup': city_signup,
        }).pipe(
            map((response: HttpResponse<any>): UserDetails => {
                return <any>response;
            })
        );
    }

    /**
     * Updates user's profile location
     */
    updateUserLocation(profileUpdates: any) {
        let url = `${environment.domain}${environment.api.user.user}${this.user.uuid}`;

        return this.http.put(url, profileUpdates).pipe(
            map((response: HttpResponse<any>): User => {
                return <any>response;
            })
        );
    }

    /**
     * Updates user's profile profile image
     */
    updateUserProfileImage(profile_image_upload_name: string): Observable<User> {
        let url = `${environment.domain}${environment.api.user.user}${this.user.uuid}/`;
        return this.http.put(url, {
            'profile_image_upload_name': profile_image_upload_name,
            'handle': this.user.handle
        }).pipe(
            map((response: HttpResponse<any>): User => {
                return (<any>response);
            })
        );
    }

    saveUserRole(userId: string, roleId: number) {
        let url = `${environment.domain}${environment.api.user.role}`;

        return this.http.post(url, {
            'user': userId,
            'role': roleId,
        }).pipe(
            map((response: HttpResponse<any>): User => {
                return (<any>response);
            })
        );
    }

    deleteUserRole(uuid: string) {
        let url = `${environment.domain}${environment.api.user.role}${uuid}/`;

        return this.http.delete(url).pipe(
            map((response: HttpResponse<any>): User => {
                return (<any>response);
            })
        );
    }

    /**
     * Updates user's CV
     */
    updateUserCv(cv: string, originalName: string): Observable<User> {
        let url = `${environment.domain}${environment.api.user.cv}`;
        let date = new Date();
        let formattedData = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
        return this.http.post(url, {
            'cv_upload_name': cv,
            'cv_name': originalName,
            label: `Primary profile CV uploaded on ${formattedData}`,
            status: PROFILE_UPLOAD_FILE_STATUS.PRIMARY,
            type: FILE_UPLOAD_SOURCE.PROFILE
        }).pipe(
            map((response: HttpResponse<any>): User => {
                return (<any>response);
            })
        );
    }

    /**
     * Updates user's CV
     */
    deleteUserCv(uuid: string): Observable<any> {
        let url = `${environment.domain}${environment.api.user.cv}${uuid}`;
        return this.http.delete(url).pipe(
            map((response: HttpResponse<any>): User => {
                return (<any>response);
            })
        );
    }

    /**
     * Loads user's skills
     */
    getUserSkills(): Observable<Skill> {
        const url = `${environment.domain}${environment.api.user.skill}?response_type=full`;
        return this.http.get(url).pipe(
            map((response: HttpResponse<any>): Skill => {
                return (<any>response).results;
            })
        );
    }

    /**
     * Loads user's languages
     */
    getUserLanguages(): Observable<Language> {
        const url = `${environment.domain}${environment.api.user.language}?response_type=full`;
        return this.http.get(url).pipe(
            map((response: HttpResponse<any>): Language => {
                return (<any>response).results;
            })
        );
    }

    getUserRequests() {
        const url = `${environment.domain}${environment.api.user.request}?response_type=full`;
        return this.http.get(url).pipe(
            map((response: HttpResponse<any>): Language => {
                return (<any>response).results;
            })
        );
    }

    getUserOffers() {
        const url = `${environment.domain}${environment.api.user.offer}?response_type=full`;
        return this.http.get(url).pipe(
            map((response: HttpResponse<any>): Language => {
                return (<any>response).results;
            })
        );
    }

    /**
     * Updates user's summary
     */
    updateUserSummary(summary: string): Observable<User> {
        let url = `${environment.domain}${environment.api.user.credential}${this.user.credentials.uuid}/`;
        return this.http.put(url, {
            'summary': summary,
        }).pipe(
            map((response: HttpResponse<any>): User => {
                return (<any>response);
            })
        );
    }

    /**
     * Updates user's phone contact
     */
    updateUserPhone(summary: string): Observable<User> {
        let url = `${environment.domain}${environment.api.user.user}${this.user.username}/`;
        return this.http.put(url, {
            'summary': summary,
        }).pipe(
            map((response: HttpResponse<any>): User => {
                return (<any>response);
            })
        );
    }

    /**
     * Create user's experience
     */
    addUserExperience(title: string, organisation: string, startDate: Date, endDate: Date, summary: string, isOrganisationCustom: boolean): Observable<Experience> {
        let url = `${environment.domain}${environment.api.user.experience}`;
        let payload = {
            "title": title,
            "experience_type": 1,
            "start_date": startDate,
            "end_date": endDate || null,
            "summary": summary
        };
        if (isOrganisationCustom) {
            payload['organisation_custom'] = organisation;
            payload['organisation'] = null;
        } else {
            payload['organisation'] = organisation;
            payload['organisation_custom'] = null;
        }

        return this.http.post(url, payload).pipe(
            map((response: HttpResponse<any>): Experience => {
                return (<any>response);
            })
        );
    }
    /**
     * Edit user's experience
     */
    editUserExperience(uuid: string, title: string, organisation: string, startDate: Date, endDate: Date, summary: string, isOrganisationCustom: boolean): Observable<Experience> {
        let url = `${environment.domain}${environment.api.user.experience}${uuid}`;
        let payload = {
            "title": title,
            "experience_type": 1,
            "start_date": startDate,
            "end_date": endDate || null,
            "summary": summary
        };
        if (isOrganisationCustom) {
            payload['organisation_custom'] = organisation;
            payload['organisation'] = null;
        } else {
            payload['organisation'] = organisation;
            payload['organisation_custom'] = null;
        }
        return this.http.put(url, payload).pipe(
            map((response: HttpResponse<any>): Experience => {
                return (<any>response);
            })
        );
    }

    /**
     * Delete user's experience
     */
    deleteUserExperience(uuid: string): Observable<Experience> {
        let url = `${environment.domain}${environment.api.user.experience}${uuid}`;
        return this.http.delete(url).pipe(
            map((response: HttpResponse<any>): Experience => {
                return (<any>response);
            })
        );
    }

    /**
     * Create user's educations
     */
    addUserEducation(organisation: string, field: string, notes: string, startDate: Date, endDate: Date, isSchoolCustom: boolean): Observable<Education> {
        let url = `${environment.domain}${environment.api.user.education}`;
        let payload = {
            "field_of_study": field,
            "activities": "All",
            "degree": "highest",
            "notes": notes,
            "start_date": startDate,
            "end_date": endDate || null,
        };
        if (isSchoolCustom) {
            payload['organisation_custom'] = organisation;
            payload['organisation'] = null;
        } else {
            payload['organisation'] = organisation;
            payload['organisation_custom'] = null;
        }
        return this.http.post(url, payload).pipe(
            map((response: HttpResponse<any>): Education => {
                return (<any>response);
            })
        );
    }

    /**
     * Edit user's educations
     */
    editUserEducation(uuid: string, organisation: string, field: string, notes: string, startDate: Date, endDate: Date, isSchoolCustom: boolean): Observable<Education> {
        let url = `${environment.domain}${environment.api.user.education}${uuid}`;
        let payload = {
            "field_of_study": field,
            "activities": "All",
            "degree": "highest",
            "notes": notes,
            "start_date": startDate,
            "end_date": endDate || null,
        };
        if (isSchoolCustom) {
            payload['organisation_custom'] = organisation;
            payload['organisation'] = null;
        } else {
            payload['organisation'] = organisation;
            payload['organisation_custom'] = null;
        }

        return this.http.put(url, payload).pipe(
            map((response: HttpResponse<any>): Education => {
                return (<any>response);
            })
        );
    }
    /**
     * Delete user's educations
     */
    deleteUserEducation(uuid: string): Observable<Education> {
        let url = `${environment.domain}${environment.api.user.education}${uuid}`;
        return this.http.delete(url).pipe(
            map((response: HttpResponse<any>): Education => {
                return (<any>response);
            })
        );
    }

    /**
     * Create user's language
     */
    addLanguage(language: string): Observable<Language> {
        let url = `${environment.domain}${environment.api.user.language}`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(url, language, { headers: headers }).pipe(
            map((response: HttpResponse<any>): Language => {
                return (<any>response);
            })
        );
    }

    /**
     * Edit user's language
     */
    editLanguage(language: string, uuid: string): Observable<Language> {
        let url = `${environment.domain}${environment.api.user.language}${uuid}/`;
        return this.http.put(url, {
            "language": language,
        }).pipe(
            map((response: HttpResponse<any>): Language => {
                return (<any>response);
            })
        );
    }

    /**
     * Remove user's language
     */
    deleteLanguage(uuid: string): Observable<Language> {
        let url = `${environment.domain}${environment.api.user.language}${uuid}/`;
        return this.http.delete(url).pipe(
            map((response: HttpResponse<any>): Language => {
                return (<any>response);
            })
        );
    }


    /**
     * Create user's skill
     */
    addSkill(skill: string): Observable<Skill> {
        let url = `${environment.domain}${environment.api.user.skill}`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(url, skill, { headers: headers }).pipe(
            map((response: HttpResponse<any>): Skill => {
                return (<any>response);
            })
        );
    }

    /**
     * Edit user's skill
     */
    editSkill(skill: string, uuid: string): Observable<Skill> {
        let url = `${environment.domain}${environment.api.user.skill}${uuid}/`;
        return this.http.put(url, {
            "skill": skill,
        }).pipe(
            map((response: HttpResponse<any>): Skill => {
                return (<any>response);
            })
        );
    }

    /**
     * Remove user's skill
     */
    deleteSkill(uuid: string): Observable<Skill> {
        let url = `${environment.domain}${environment.api.user.skill}${uuid}/`;
        return this.http.delete(url).pipe(
            map((response: HttpResponse<any>): Skill => {
                return (<any>response);
            })
        );
    }

    /**
     * Sets doc upload step data
     * @param value new value
     */
    setDocsUploadStepData(value: any) {
        if (value) {
            this.docsUploadStepData$.next({ ...value, ...this.docsUploadStepData$.value });
        }
    }

    /**
     * Get user's connection and experience
     */
    getUserInternational() {
        const url = `${environment.domain}${environment.api.user.country}?response_type=full`;
        return this.http.get(url).pipe(
            map((response: HttpResponse<any>): any => {
                return (<any>response).results;
            })
        );
    }

    /**
     * Save user's connection and experience
     */
    addUserInternational(country: number, country_status: number) {
        let url = `${environment.domain}${environment.api.user.country}`;
        return this.http.post(url, {
            country,
            country_status
        }).pipe(
            map((response: HttpResponse<any>): Skill => {
                return (<any>response);
            })
        );
    }

    /**
    * Save user's connection and experience
    */
    updateUserInternational(uuid: string, country: number, country_status: number) {
        let url = `${environment.domain}${environment.api.user.country}${uuid}/`;
        return this.http.put(url, {
            country,
            country_status
        }).pipe(
            map((response: HttpResponse<any>): Skill => {
                return (<any>response);
            })
        );
    }

    /**
     * Gets stage tracker record
     */
    private _getStageTrackerRecord(profileTracker: ProfileTracker[], stageId: number) {
        return profileTracker.find((item) => item.stage === stageId);
    }

}
