import { Skill } from './../profile/types/helpers/skill.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { Country } from './types/country.model';
import { environment } from '../../../src/environments/environment';
import { PrimaryFunction } from './types/primary-function.model';
import { Filter } from './types/filter.model';
import { Language } from './types/language.model';
import { USER_ROLE_OPTIONS } from './types/user-roles.const';
import { AppPermission } from './types/app-permissions.model';
import * as moment from 'moment';
import { APP_LISTING_TYPE } from './types/app-listing-types.enum';
import { PresignedUrlResponse } from '../careers/types/presigned-url-response.model';
import { ProfileService } from '../profile/profile.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';



export enum RESOURCE_TYPE {
  SALES_ENQUIRY_ATTACHMENT = 'sales-enquiry-attachment'
}
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  COUNTRIES_DEFAULT_LIMIT = 500;
  INDUSTRIES_DEFAULT_LIMIT = 500;
  // TODO: Replace this with behavior subject to keep latest value of role from api
  currentUserRole = USER_ROLE_OPTIONS.anonymous;
  userPermissions$: BehaviorSubject<AppPermission> = new BehaviorSubject<AppPermission>(new AppPermission());
  referenceTypes$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  onboardingTrackerStages$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  stepData = {
    imageUrl: '',
  };

  constructor(private http: HttpClient, private profileService: ProfileService, private modalService: NgbModal) {

    // this.getUserRolesConfiguration();
  }

  /**
   * Gets org data by domain from MMB
   * @param domain 
   */
  getOrgDataByDomain(domain: string) {
    const url = `${environment.domain}${environment.api.public.organisationSearch}?domain=${domain}&response_type=full`;
    return this.http.get(url);
  }

  /**
   * Gets org data by domain
   * @param domain 
   */
  getOrgDataByDomainExternally(domain: string) {
    const url = `${environment.api.public.companyEnrichment}?api_key=${environment.companyEnrichmentKey}&domain=${domain}`;
    return this.http.get(url);
  }

  /**
   * Gets org data by domain
   * @param domain 
   */
  getOrgLogoByDomain(domain: string) {
    const url = `${environment.api.public.companyLogo}/${domain}?size=256&format=png`;
    return this.http.get(url);
  }

  /**
  * Gets countries
  * @param searchString 
  * @param limit 
  */
  getCountries(searchString: string = '', limit: number = this.COUNTRIES_DEFAULT_LIMIT): Observable<Country[]> {
    const url = `${environment.domain}${environment.api.public.country}?name=${searchString}&limit=${limit}`;

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): Country[] => {
        return (response as any).results;
      })
    );
  }

  getCountryCodes(){
    const url = `${environment.domain}${environment.api.public.phoneCode}?limit=500`;
    return this.http.get(url).pipe(
      map((res:any) => res.results)
    );
  }

  getStrengths() {
    const url = `${environment.domain}${environment.api.public.strength}?category=True`;

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): Country[] => {
        return (response as any).results;
      })
    );
  }

  getCountryById(id: string) {
    const url = `${environment.domain}${environment.api.public.country}?value=${id}`;

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): Country[] => {
        return (response as any).results;
      })
    );
  }

  getCountryStatusReferenceType() {
    const url = `${environment.domain}${environment.api.onboarding.countryStatus}`;

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): any[] => {
        return (response as any).results;
      })
    );
  }

  /**
    * Save user's connection and experience
    */
  addCountryConnection(country: number, country_status: number) {
    let url = `${environment.domain}${environment.api.user.country}`;
    return this.http.post(url, {
      country,
      country_status
    }).pipe(
      map((response: HttpResponse<any>): Skill => {
        return (<any>response);
      })
    );
  }

  /**
    * Delete user's connection and experience
    */
  deleteCountryConnection(uuid: string) {
    let url = `${environment.domain}${environment.api.user.country}${uuid}/`;
    return this.http.delete(url);
  }

  /**
    * Save user's connection and experience
    */
  deleteCountryConnectionsBulk(countryCode: string) {
    let url = `${environment.domain}${environment.api.onboarding.countryStatusBulkDelete}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: {
        country_code: countryCode
      }
    };
    return this.http.delete(url, httpOptions);
  }

  getListingType(listingType: APP_LISTING_TYPE) {
    switch (listingType) {
      case APP_LISTING_TYPE.LISTING_INTERNAL_BASIC: {
        return 'Verified';
      }
      case APP_LISTING_TYPE.LISTING_INTERNAL_STANDARD: {
        return 'Preferred';
      }
      case APP_LISTING_TYPE.LISTING_INTERNAL_PREMIUM: {
        return 'Priority';
      }
      case APP_LISTING_TYPE.LISTING_EXTERNAL_BASIC: {
        return 'Approved';
      }
      case APP_LISTING_TYPE.LISTING_EXTERNAL_STANDARD: {
        return 'Verified';
      }
      case APP_LISTING_TYPE.LISTING_EXTERNAL_PREMIUM: {
        return 'Preferred';
      }
    }
  }

  /**
  * Gets region
  * @param searchString 
  * @param limit 
  */
  getRegions(searchString: string = '', limit: number = this.COUNTRIES_DEFAULT_LIMIT) {
    const url = `${environment.domain}${environment.api.public.region}?name=${searchString}&limit=${limit}`;

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): Country[] => {
        return (response as any).results;
      })
    );
  }

  /**
  * Gets industries
  * @param searchString 
  * @param limit 
  */
  getSecondaryIndustries(searchString: string = '', limit: number = this.INDUSTRIES_DEFAULT_LIMIT) {
    const url = `${environment.domain}${environment.api.public.secondaryIndustries}?name=${searchString}&limit=${limit}`;

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): Country[] => {
        return (response as any).results;
      })
    );
  }

  getOrgs(searchString: string = '', limit: number = this.COUNTRIES_DEFAULT_LIMIT): Observable<Country[]> {
    const url = `${environment.domain}${environment.api.public.organisation}?name=${searchString}&limit=${limit}`;

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): Country[] => {
        return (response as any).results;
      })
    );
  }

  getSchools(searchString: string = '', limit: number = this.COUNTRIES_DEFAULT_LIMIT): Observable<Country[]> {
    const url = `${environment.domain}${environment.api.public.organisation}?category=educational&name=${searchString}&limit=${limit}`;

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): Country[] => {
        return (response as any).results;
      })
    );
  }

  getSkills(searchString: string = '', limit: number = this.COUNTRIES_DEFAULT_LIMIT): Observable<Skill[]> {
    const url = `${environment.domain}${environment.api.public.skills}?name=${searchString}&limit=${limit}`;

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): Skill[] => {
        return (response as any).results;
      })
    );
  }

  getLanguages(searchString: string = '', limit: number = this.COUNTRIES_DEFAULT_LIMIT): Observable<Language[]> {
    const url = `${environment.domain}${environment.api.public.languages}?name=${searchString}&limit=${limit}`;

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): Language[] => {
        return (response as any).results;
      })
    );
  }

  /**
   * Gets functions
   * @param searchString 
   * @param limit 
   */
  getPrimaryFunctions(searchString: string = '', limit: number = 500): Observable<PrimaryFunction[]> {
    const url = `${environment.domain}${environment.api.public.primaryFunctions}?name=${searchString}&limit=${limit}`;

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): PrimaryFunction[] => {
        return (response as any).results;
      })
    );
  }


  /**
   * Gets industries
   * @param searchString 
   * @param limit 
   */
  getPrimaryIndustries(searchString: string = '', limit: number = 500): Observable<PrimaryFunction[]> {
    const url = `${environment.domain}${environment.api.public.primaryIndustries}?name=${searchString}&limit=${limit}`;

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): PrimaryFunction[] => {
        return (response as any).results;
      })
    );
  }

  getReferenceTypes() {
    const url = `${environment.domain}${environment.api.public.referenceTypesAll}`;

    return this.http.get(url).pipe(
      shareReplay(1),
      map((response: HttpResponse<any>): any => {
        return (response as any);
      })
    );
  }

  getOnboardingStagesInfo() {
    const url = `${environment.domain}${environment.api.onboarding.trackerStages}`;

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): any => {
        return (response as any);
      })
    );
  }

  getRoleReferenceType(searchQuery?: string) {
    let url = `${environment.domain}${environment.api.public.referenceTypeRole}?limit=1000`;

    if (searchQuery) {
      url = `${url}&q=${searchQuery}`;
    }

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): any => {
        return (response as any).results;
      })
    );
  }

  getCauseReferenceType() {
    const url = `${environment.domain}${environment.api.public.referenceTypeCause}?active=True&category=False`;

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): any => {
        return (response as any).results;
      })
    );
  }

  /**
   * Gets user roles configuration
   */
  getUserRolesConfiguration() {
    const url = `/assets/data/roles-configuration.json`;

    this.http.get(url).pipe(
      map((response: HttpResponse<any>): any[] => {
        return (response as any).roles;
      })
    ).subscribe((response: any[]) => {
      this.userPermissions$.next(response.find((item) => item.role === this.currentUserRole.key));
    });
  }

  /**
   * Gets currencies
   * @param searchString search string
   * @param limit page size
   */
  getCurrencies(searchString: string = '', limit: number = this.COUNTRIES_DEFAULT_LIMIT): Observable<Country[]> {
    const url = `${environment.domain}${environment.api.public.currency}?name=${searchString}&limit=${limit}`;

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): Country[] => {
        return (response as any).results;
      })
    );
  }

  /**
   * Gets cities of a country
   */
  getCitiesByCountry(countryCode: string, searchString: string = '', limit: number = this.COUNTRIES_DEFAULT_LIMIT) {
    let url = `${environment.domain}${environment.api.public.city}?country=${countryCode}&limit=${limit}`;
    if (searchString) {
      url = `${url}&name=${searchString}`;
    }

    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): Country[] => {
        return (response as any).results;
      })
    );
  }

  /**
   * Gets language proficiency levels
   */
  getLanguageProficiencyLevels() {
    const url = `${environment.domain}${environment.api.user.languageLevel}`;
    return this.http.get(url).pipe(
      map((response: HttpResponse<any>): any[] => {
        return (response as any).results;
      }));
  }

  /**
   * Applies filter to url
   * @param url 
   * @param filter 
   */
  applyFilterToUrl(url, filter: Filter) {
    if (filter) {
      if (filter.keyword) {
        url = `${url}&headline=${filter.keyword}`;
      }
      if (filter.country) {
        url = `${url}&countries_code=${filter.country}`;
      }
      if (filter.city) {
        url = `${url}&city=${filter.city}`;
      }
      if (filter.function) {
        url = `${url}&functions=${filter.function}`;
      }
      if (filter.industry) {
        url = `${url}&industries_primary=${filter.industry}`;
      }
      if (filter.careerType) {
        url = `${url}&career_type=${filter.careerType}`;
      }
      if (filter.eventType) {
        url = `${url}&event_type=${filter.eventType}`;
      }
      if (filter.initiativeType) {
        url = `${url}&initiative_type=${filter.initiativeType}`;
      }
      if (filter.seniorityType) {
        url = `${url}&seniority=${filter.seniorityType}`;
      }
    }
    return url;
  }

  /**
   * User wants to follow user
   */
  followUser(id: number) {
    const data = {
      user: id
    };
    const url = `${environment.domain}${environment.api.user.followUser}`;
    return this.http.post(url, data).pipe(
      map((response: HttpResponse<any>) => {
        return (<any>response);
      })
    );
  }

  /**
   * User wants to unfollow user
   */
  unfollowUser(uuid: string) {
    const url = `${environment.domain}${environment.api.user.followUser}${uuid}`;
    return this.http.delete(url).pipe(
      map((response: HttpResponse<any>) => {
        return (<any>response);
      })
    );
  }

  /**
   * Gets time left
   * @param endDate end date
   */
  getTimeLeft(endDate: string): { duration: number, type: string } {
    const end: any = moment(endDate);
    const today: any = moment(new Date());
    let daysLeft = end.diff(today, 'days');
    if (daysLeft === 0) {
      let hoursLeft = end.diff(today, 'hours');

      if (hoursLeft === 0) {
        let minutesLeft = end.diff(today, 'minutes');

        return {
          duration: minutesLeft,
          type: 'minutes'
        };
      }

      return {
        duration: hoursLeft,
        type: 'hours'
      };
    }
    return {
      duration: daysLeft,
      type: 'days'
    };;
  }

  /**
 * Get location
 * @param {string} country
 * @param {string} city
 * @param {string} cityCustom
 */
  getLocation(country: string, city: string, cityCustom: string): string {
    let location: string = city;
    if (!city && cityCustom) {
      location = cityCustom;
    }

    if (country) {
      // country exists
      return location ? `${location}, ${country}` : country;
    } else if (location) {
      // city/city_custom only
      return location;
    }

    // placeholder value
    return 'Location not provided';
  }

  getOrgLogoByExternalSource(orgName: string) {
    return this.http.get(`https://logo.clearbit.com/${orgName}.com`);
  }

  /**
    * Gets presigned url
    */
  getPresignedUrl(file: File, type: RESOURCE_TYPE): Observable<PresignedUrlResponse> {
    let data = {
      file_name: file.name,
      resource_type: 'sales-enquiry-attachment'
    };
    this.profileService.setDocsUploadStepData(data);
    let url = `${environment.domain}${environment.api.public.presignedUrl}`;

    return this.http.post(url, data).pipe(
      map((response: HttpResponse<any>): PresignedUrlResponse => {
        return (<any>response);
      })
    );
  }

  /**
   * Uploads file to AWS
   * @param presignedUrl presigned url from AWS
   * @param file Upload file
   */
  uploadFile(presignedUrl: any, file: File, type: RESOURCE_TYPE = RESOURCE_TYPE.SALES_ENQUIRY_ATTACHMENT) {
    const formData = new FormData();
    formData.append('key', presignedUrl.fields.key);
    formData.append('policy', presignedUrl.fields.policy);
    formData.append('x-amz-date', presignedUrl.fields['x-amz-date']);
    formData.append('x-amz-security-token', presignedUrl.fields['x-amz-security-token']);
    formData.append('x-amz-credential', presignedUrl.fields['x-amz-credential']);
    formData.append('x-amz-algorithm', presignedUrl.fields['x-amz-algorithm']);
    formData.append('x-amz-signature', presignedUrl.fields['x-amz-signature']);
    if (presignedUrl.fields['acl']) {
      formData.append('acl', presignedUrl.fields['acl']);
    }
    formData.append('file', file);
    // set the url name for confirmation step
    if (type === RESOURCE_TYPE.SALES_ENQUIRY_ATTACHMENT) {
      this.stepData.imageUrl = presignedUrl.fields.key;
    }
    return this.http.post(
      presignedUrl.url, formData).pipe(
        map((response: HttpResponse<any>): any => {
          return (<any>response);
        })
      );
  }



  /**
   * Submits organization enquiry form
   */
  submitReferralForm(referralData: any, isLoggedIn?: boolean) {
    let url = `${environment.domain}${environment.api.public.referral}`;
    if (isLoggedIn) {
      url = `${environment.domain}${environment.api.user.referral}`;
    }
    return this.http.post(url, referralData);
  }

  /**
  * Submits career to referrals
  */
  submitCareerReferralForm(payload: any, isLoggedIn?: boolean) {
    let url = `${environment.domain}${environment.api.public.careerReferral}`;
    if (isLoggedIn) {
      url = `${environment.domain}${environment.api.user.careerReferral}`;
    }
    return this.http.post(url, payload);
  }

  /**
  * Submits event to referrals
  */
  submitEventReferralForm(payload: any, isLoggedIn?: boolean) {
    let url = `${environment.domain}${environment.api.public.eventReferral}`;
    if (isLoggedIn) {
      url = `${environment.domain}${environment.api.user.eventReferral}`;
    }
    return this.http.post(url, payload);
  }

  /**
  * Submits experience to referrals
  */
  submitExperienceReferralForm(payload: any, isLoggedIn?: boolean) {
    let url = `${environment.domain}${environment.api.public.experienceReferral}`;
    if (isLoggedIn) {
      url = `${environment.domain}${environment.api.user.experienceReferral}`;
    }
    return this.http.post(url, payload);
  }

}
