import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthToken } from '../types/auth-token.model';
import { Subscription } from 'rxjs';
import PubSub from 'pubsub-js';
import { WEB_APP_EVENTS } from '../../shared/types/app-events.enum';
import { NavbarService } from '../../shared/navbar/navbar.service';
import { environment } from 'projects/mmb-web-app/src/environments/environment';
import { SharedHelperService } from '../../shared/shared-helper.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'mmb-web-app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @Output() registerClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() loginSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Input() isOpenedInModal: boolean;
  loginForm: UntypedFormGroup;
  errorMessage: string[];
  authStateSubscriber$: Subscription;
  linkedInCredentials = {
    clientId: environment.linkedIn.clientId,
    redirectUrl: `${window.location.origin}/login`,
    scope: environment.linkedIn.scope // To read basic user profile data and email
  };
  linkedInToken: string;
  environment = environment;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedHelperService: SharedHelperService,
    private toastr: ToastrService
  ) {
    this.sharedHelperService.setPageMeta();
    localStorage.clear();
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    this.linkedInToken = this.route.snapshot.queryParams['code'];

    if (this.linkedInToken) {
      this._loginByLinkedin();
    }
  }

  /**
   * Try to log in the user
   */
  onLogin() {

    if (!this.loginForm.valid) {
      this.loginForm.markAllAsTouched();
      return;
    }
      this.authService.login(this.loginForm.controls['email'].value, this.loginForm.controls['password'].value)
          .subscribe((response: AuthToken) => {
                if (response && response.token) {
                  this.authService.saveUserToken(response.token, response.expiry);
                  localStorage.setItem('user', JSON.stringify(response.user));
                  this.authService.isAuthenticated();
                  // // TODO: Route url contains the requested url
                  // // If onboarding completed, take user to redirect Url
                  // PubSub.publish(WEB_APP_EVENTS.LOGIN_SUCCESS);
                  // PubSub.publish(WEB_APP_EVENTS.GET_USER_TRACKER_INFO, { gp: false, routeUrl: this.authService.redirectUrl });
                  this.loginSuccess.emit();
                }
              },
              (err) => {
                if (err.error && err.error.non_field_errors) {
                  this.errorMessage = err.error.non_field_errors;
                  return;
                }
                this.sharedHelperService.handleError(err);
              })
    }



  /**
   * Log out
   */
  onLogout() {
    try {
      this.authService.removeUserToken();
      this.router.navigate(['/login']);
    } catch (ex) {

    }
  }

  onApplyToJoin() {
    if (this.isOpenedInModal) {
      this.registerClicked.emit();
      return;
    }
    this.router.navigate(['/join']);
  }

  signInWithLinkedIn(): void {
    window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${this.linkedInCredentials.clientId
      }&redirect_uri=${this.linkedInCredentials.redirectUrl}&scope=${this.linkedInCredentials.scope}`;
  }

  ngOnDestroy() {
    if (this.authStateSubscriber$) {
      this.authStateSubscriber$.unsubscribe();
    }
  }

  private _loginByLinkedin() {
    // send call to retrieve details
    this.authService.getLinkedinProfileDetails(this.linkedInToken, this.linkedInCredentials.redirectUrl).subscribe((response) => {
    }, (err) => {
    })

    // this.authService.linkedinLoginToMovemeback(this.linkedInToken).subscribe((resp) => {
    //   if (resp) {
    //     this.authService.saveUserToken(resp.token, resp.expires);
    //     if (this.router.url === '/login' || this.router.url === '/join') {
    //       PubSub.publish(WEB_APP_EVENTS.GET_USER_TRACKER_INFO, {
    //         gp: true
    //       });
    //     }
    //   }
    // });
  }
}
