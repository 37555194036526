import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Initiative } from '../../initiatives/types/initiative.model';
import { SharedService } from '../shared.service';

@Component({
  selector: 'mmb-web-app-experience-org-summary-info',
  templateUrl: './experience-org-summary-info.component.html',
  styleUrls: ['./experience-org-summary-info.component.scss']
})
export class ExperienceOrgSummaryInfoComponent implements OnInit {
  @Input() slug: string;
  @Input() experience: any;
  @Input() image: string;
  @Input() name: string;
  @Input() professionalTitle: string;
  @Input() company: string;
  @Input() type: string;
  @Input() createdOnDate: Date;
  @Input() id: number;
  @Input() isUser: boolean;
  @Input() popoverSummary: string;
  @Input() userUrl: string;
  @Input() orgUrl: string;
  @Input() redirect: string;
  @Input() location: string;
  @Input() isSaved: boolean;
  @Input() isActionsEnabled: boolean = true;

  @Output() bookmark: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() removeBookmark: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private sharedService: SharedService) { }

  ngOnInit() {
  }

  /**
   * Time left
   */
  getTimeLeft(): { duration: number, type: string } {
    return this.sharedService.getTimeLeft(this.experience.deadline_date);

  }
  
  /**
   * Add bookmark
   */
  onBookmark(ev: any) {
    this.bookmark.emit();
    ev.stopPropagation();
  }
  
  getShareUrl() {
    return `${window.location.href}/${this.redirect}`;
  }
  /**
   * Remove bookmark
   */
  onRemoveBookmark(ev: any) {
    this.removeBookmark.emit();
    ev.stopPropagation();
  }

}
