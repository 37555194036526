import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Validators, UntypedFormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../../public/auth.service';
import { ActivatedRoute } from '@angular/router';
import { AuthToken } from '../../public/types/auth-token.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PROFILE_STAGES } from '../../profile/types/profile-stages.model';
import { environment } from 'projects/mmb-web-app/src/environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SafeStyle } from '@angular/platform-browser';
import { PROFILE_TRACKER_STAGE_STATUS } from '../../profile/types/profile-tracker.model';
import { ProfileService } from '../../profile/profile.service';
import { Subject, Subscription} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedHelperService } from '../shared-helper.service';
import * as prettyName from 'pretty-name';
import * as Sentry from '@sentry/angular-ivy';
import { OriginalName, Register_Initial_Title, Register_Linked_Credentials, Register_Static_Images } from './register.constants';
import { fadeAnimation } from 'themes/mmb-web-v1-theme/animations/fade-in-out';
import { FORM_ERROR_FN } from '../common-form-components/form-field-validation';
import type { FormValidationFunction } from '../common-form-components/form-field-validation';

@Component({
    selector: 'mmb-web-app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    animations:[fadeAnimation]
})
export class RegisterComponent implements OnInit, OnDestroy {
    @Output() completed: EventEmitter<any> = new EventEmitter<any>();
    @Input() isOpenedInModal: boolean;
    @Input() deepLinkedPage: string;
    @Input() deepLinkedAction: string;
    @Input() title: string = Register_Initial_Title;
    @Input() subtitle = 'To access the full benefits of Movemeback become a member;  please login or apply to join to continue';
    private _unsubscribeAll:Subject<null> = new Subject<null>();
    INITIAL_TITLE = Register_Initial_Title;
    registerForm:FormGroup;
    internalServerError: string;
    linkedInCredentials = Register_Linked_Credentials;
    images:string[] = Register_Static_Images;
    randomImage: SafeStyle;
    formattedEmail: string;
    originalEmail: string;
    formattedName: string;
    isEmailValidationInProgress = false;
    isEmailValid = true;
    autoCorrectedEmail: string;
    originalName: OriginalName = {};
    isLoading:boolean = false;
    submitButtonTouched:boolean = false;
    isLoginEnabled:boolean;
    errors:any;

    constructor(
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private activeModal: NgbActiveModal,
        private http: HttpClient,
        private profileService: ProfileService,
        private sharedHelperService: SharedHelperService,
        private route: ActivatedRoute,
        @Inject(FORM_ERROR_FN) public validateField :FormValidationFunction,
    ) {
    }

    ngOnInit() {
        this.createRegisterForm();
        this.getInviteCode();
        this.getRandomImage();
    }

    createRegisterForm(){
        this.registerForm = this.fb.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password1: ['', Validators.required],
            invite_code: [''],
            phone_number:[null, Validators.required]
        });
    }

    getInviteCode(){
        this.route.queryParams.subscribe((params) => {
            if (params && params.invite_code) {
                this.registerForm.controls.invite_code.setValue(params.invite_code);
            }
        })
    }

    /**
     * Formats name as per industry standard
     */
    getFormattedName() {
        const firstName = this.registerForm.controls.first_name.value;
        const lastName = this.registerForm.controls.last_name.value;
        this.formattedName = null;
        if (firstName && lastName && firstName.trim() !== '' && lastName.trim() !== '') {
            const formattedName = prettyName(`${firstName} ${lastName}`);
            if (`${firstName} ${lastName}` !== formattedName) {
                this.originalName.firstName = firstName;
                this.originalName.lastName = lastName;
                const splitName = formattedName.split(' ');
                const formattedFirstName = splitName.shift();
                this.registerForm.controls.first_name.setValue(formattedFirstName);
                this.registerForm.controls.last_name.setValue(splitName.join(' '));
                this.formattedName = formattedName;
            }

            return formattedName;
        }
    }

    /**
     * Format email to lower case
     * @returns
     */
    getFormattedEmail() {
        this.originalEmail = this.registerForm.controls.email.value;
        if (this.originalEmail !== this.originalEmail.toLowerCase()) {
            this.formattedEmail = this.originalEmail.toLowerCase();
            this.registerForm.controls.email.setValue(this.formattedEmail);
        }
    }

    /**
     * User accepted the name change
     */
    onAcceptFormattedEmail() {
        this.formattedEmail = null;
    }

    /**
     * User rejected the automatic name change
     */
    onRejectFormattedEmail() {
        this.formattedEmail = null;
        this.registerForm.controls.email.setValue(this.originalEmail);
    }

    /**
     * User accepted the name change
     */
    onAcceptFormattedName() {
        this.formattedName = null;
    }

    /**
     * User rejected the automatic name change
     */
    onRejectFormattedName() {
        this.formattedName = null;
        this.registerForm.controls.first_name.setValue(this.originalName.firstName);
        this.registerForm.controls.last_name.setValue(this.originalName.lastName);
    }

    /**
     * Checks if email is valid/deliverable
     * @returns boolean
     */
     emailValidator(): Subscription {
         this.isEmailValidationInProgress = true;
        return this.http.get ('https://emailvalidation.abstractapi.com/v1/' + `?api_key=${environment.abstractApiAuthKey}` + `&email=${this.registerForm.controls.email.value}`)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe ((res: any) => {
                const isEmailDeliverable = res.deliverability;
                if (isEmailDeliverable === 'UNDELIVERABLE') {
                    this.isEmailValid = false;
                    this.isEmailValidationInProgress = false;
                    Sentry.captureException(new Error(`The following Email is blocked by AbstractAPI: ${this.registerForm.controls.email.value}`), {
                        tags: {
                            section: 'register',
                        },
                    });
                    return false;
                } else {
                    if (res.autocorrect && res.quality_score<0.65) {
                        this.autoCorrectedEmail = res.autocorrect;
                    } else {
                        this.autoCorrectedEmail = null;
                    }
                    this.isEmailValid = true;
                    this.isEmailValidationInProgress = false;
                    this.getFormattedEmail()
                    return true;
                }
            }, error => {
                Sentry.captureException(new Error(`AbstractAPI is not responding, so we are bypassing it`), {
                    tags: {
                        section: 'register',
                    },
                });
                return true;
            });
    }

    /**
     * Try to login the user
     */
    onRegister() {

        this.submitButtonTouched = true;
        if (this.registerForm.invalid || !this.isEmailValid) {
            this.registerForm.markAllAsTouched();
            return;
        }

        this.isLoading = true;
        this.errors = null;

        // const phoneNumber = this.registerForm.controls.countryRegion.value?.code + this.registerForm.controls.phoneNumber.value;
        const submitObject = this.registerForm.value;
        this.sharedHelperService.removeEmptyItems(submitObject);
        this.authService._register(submitObject).pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next:(response: AuthToken) => {
                    if (response && response.token) {
                        this.authService.saveUserToken(response.token, response.expiry);
                        localStorage.setItem('user', JSON.stringify(response.user));
                        this.isLoading = false;
                        setTimeout(() => {
                            this.profileService.setUserProfileTracker(PROFILE_STAGES.USER_SIGNUP, PROFILE_TRACKER_STAGE_STATUS.COMPLETED).subscribe((response) => {
                                this.completed.emit();
                                this.authService.isAuthenticated();
                            });
                        }, 0);
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.errors = err.error.detail;
                    this.isLoading = false;
                    if (err && err.error && err.error.non_field_errors) {
                        this.internalServerError = err.error.non_field_errors;
                        return;
                    }
                    this.sharedHelperService.handleError(err);
                }
            });
    }


    onLoginSuccess() {
        window.location.reload();
    }

    signInWithLinkedIn(): void {
        window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${this.linkedInCredentials.clientId
            }&redirect_uri=${this.linkedInCredentials.redirectUrl}&scope=${this.linkedInCredentials.scope}`;
    }

    onClose() {
        this.activeModal.close();
    }

    getRandomImage(): void {
        const randomIndex = Math.floor(Math.random() * this.images.length);
        const randomImageUrl = this.images[randomIndex];
        this.randomImage = (`url(${randomImageUrl})`);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
