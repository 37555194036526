import { Component, OnInit, Input, Output, EventEmitter, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreateNewAnswerComponent } from '../../forum/modal-create-new-answer/modal-create-new-answer.component';
import { ProfileService } from '../../profile/profile.service';
import { AuthService } from '../../public/auth.service';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { RegisterComponent } from '../register/register.component';
import { GLOBAL_SETTINGS } from '../types/global-setting.const';
import { UserTrackersService } from '../user-trackers.service';

@Component({
  selector: 'mmb-web-app-question-answer',
  templateUrl: './question-answer.component.html',
  styleUrls: ['./question-answer.component.scss']
})
export class QuestionAnswerComponent implements OnInit {
  @Output() addBookmark: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeBookmark: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUpvoteClickedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDownvoteClickedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAddNewCommentEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUpvoteCommentClickedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDownvoteCommentClickedEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() answer;
  @Input() isAuthenticated: boolean;
  @Input() truncate: any = GLOBAL_SETTINGS.opportunity.truncate;

  commentForm: UntypedFormGroup;
  firstName: string;
  user: any;

  constructor(private modalService: NgbModal,
    private authService: AuthService, private fb: UntypedFormBuilder,
    private profileService: ProfileService,
    private router: Router, private userTrackersService: UserTrackersService) {
    this.commentForm = this.fb.group({
      commentControl: ['']
    })
  }

  ngOnInit() {
    this.profileService.userProfile$.subscribe((value) => {
      this.user = value;
    });
  }

  onViewPortChange(ev) {
    if (ev && ev.visible) {
      const data = {
        object_uuid: this.answer.uuid,
        name: `${this.answer.user.name} - ${this.answer.title}`,
        slug: this.answer.slug,
        area: 'Forum',
        type: 'In View',
        additional_info: 'forum-listing-logged'
      };

      this.userTrackersService.onImpressions(data).subscribe();
    }
  }

  onRedirectToDetails(answer: any, ev) {
    if (!this.isAuthenticated) {
      this._openRegisterModal();
      ev.stopPropagation();
      return false;
    }
    this.router.navigate([`/forum/${answer.question.slug}`]);
  }

  onNavigateToAnswersSection(ev) {
    this.router.navigate([`/forum/${this.answer.question.slug}`], {
      queryParams: {
        navigate: true
      }
    });
    if (ev) {
      ev.stopPropagation();
    }
  }

  /**
   * Upvote clicked for forum
   */
  onForumQuestionUpvoteClicked(event) {
    if (this.isAuthenticated) {
      this.onUpvoteClickedEvent.emit(this.answer);
    } else {
      this._openRegisterModal();
    }
  }

  /**
   * Downvote clicked for forum
   */
  onForumQuestionDownvoteClicked(event) {
    if (this.isAuthenticated) {
      this.onDownvoteClickedEvent.emit(this.answer);
    } else {
      this._openRegisterModal();
    }
  }


  /**
   * Upvote clicked for forum
   */
  onForumQuestionCommentUpvoteClicked(event) {
    if (this.isAuthenticated) {
      this.onUpvoteCommentClickedEvent.emit(event);
    } else {
      this._openRegisterModal();
    }
  }

  /**
   * Downvote clicked for forum
   */
  onForumQuestionCommentDownvoteClicked(event) {
    if (this.isAuthenticated) {
      this.onDownvoteCommentClickedEvent.emit(event);
    } else {
      this._openRegisterModal();
    }
  }

  onForumAnswerCommentUpvoteClicked(event) {
    this.onUpvoteCommentClickedEvent.emit(event);
  }

  onForumAnswerCommentDownvoteClicked(event) {
    this.onDownvoteCommentClickedEvent.emit(event);
  }

  /**
   * Bookmark clicked for forum
   */
  onBookmarkClicked(event) {
    if (this.isAuthenticated) {
      this.addBookmark.emit(this.answer);
    } else {
      this._openRegisterModal();
    }
  }

  onRemoveBookmarkClicked(event) {
    if (this.isAuthenticated) {
      this.removeBookmark.emit(this.answer);
    } else {
      this._openRegisterModal();
    }
  }

  /**
   * Add new comment for answer
   */
  onAddNewComment(comment) {
    if (this.isAuthenticated) {
      let param = [
        comment,
        this.answer
      ]
      this.onAddNewCommentEvent.emit(param);
    } else {
      this._openRegisterModal();
    }
  }

  /**
   * Add answer for answer
   */
  onAnswer(ev) {
    if (!this.isAuthenticated) {
      this._openRegisterModal();
    } else {
      this._openAnswerModal();
    }
    ev.stopPropagation();
  }

  getTooltipTextIfLoggedOut() {
    if (!this.authService.isAuthenticated$.value) {
      return 'Sign up or Log in to use this feature';
    }
    return '';
  }

  private _openAnswerModal() {
    const modalRef = this.modalService.open(ModalCreateNewAnswerComponent, { centered: true, size: 'md' });
    modalRef.componentInstance.answerId = this.answer.id;
    modalRef.componentInstance.answerSuccess.subscribe((response) => {
      this.answer.answers.unshift(response);
      modalRef.dismiss();
    });
  }

  private _openRegisterModal() {
    const modalRef = this.modalService.open(RegisterComponent, {
      centered: true,
      size: 'md'
    });
    modalRef.componentInstance.isOpenedInModal = true;
    modalRef.componentInstance.completed.subscribe(() => {
      modalRef.dismiss();
    });
  }

}
