import { Initiative } from './../initiatives/types/initiative.model';
import { Career } from './../shared/types/career.model';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { Organisation } from './types/organisation.model';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { OrganisationCareer } from './types/organisation-career.model';
import { OrganisationEvent } from './types/organisation-event.model';
import { OrganisationInitiative } from './types/organisation-initiative.model';
import { MmbEvent } from '../events/types/event.model';
import { AuthService } from '../public/auth.service';

@Injectable({
    providedIn: 'root',
})
export class OrganisationService {
    selectedOrganisation$: BehaviorSubject<Organisation> = new BehaviorSubject<Organisation>(null);

    constructor(private http: HttpClient, private authService: AuthService) { }

    /**
     * Create organisation
     * @param payload 
     */
    createOrganisation(payload: any) {
        let url = `${environment.domain}${environment.api.user.organisation}`;
        return this.http.post(url, payload);
    }

    /**
     * Get organisation details by name
     * @param name 
     */
    getOrganisationByName(name: string) {
        let url = `${environment.domain}${environment.api.organisation.organisationSearch}?name=${name}`;
        return this.http.get(url);
    }

    /**
     * Create employee record
     */
    createEmployee(payload: any) {
        let url = `${environment.domain}${environment.api.user.employee}`;
        return this.http.post(url, payload);
    }

    /**
     * Get organisation details by uuid
     * @param uuid 
     */
    getOrganisationByUuid(uuid: string) {
        let url = `${environment.domain}${environment.api.organisation.organisationSearch}${uuid}/?response_type=full`;
        return this.http.get(url).pipe(
            tap((response: any) => {
                this.selectedOrganisation$.next(response);
            })
        );
    }

    /**
     * Gets organisation details by slug
     * @param slug organisation slug
     */
    getOrganisationDetails(slug: string): Observable<Organisation> {
        let url = `${environment.domain}${environment.api.organisation.organisation}${slug}/?response_type=full`;

        return this.http.get(url).pipe(
            map((response: HttpResponse<any>): Organisation => {
                return (<any>response);
            })
        );
    }

    /**
     * Get organisation careers
     */
    getOrganisationCareers(offset: number, limit: number, uuid?: string): Observable<Career[]> {
        let url = `${environment.domain}${environment.api.organisation.loggedOutCareer}?limit=${limit}&offset=${offset}&organisation=${uuid}`;
        if (this.authService.isAuthenticated$.value) {
            url = `${environment.domain}${environment.api.organisation.loggedInCareer}?limit=${limit}&offset=${offset}&organisation=${uuid}`;
        }


        return this.http.get(url).pipe(
            map((response: HttpResponse<any>): Career[] => {
                return (<any>response).results;
            })
        );
    }

    /**
     * Get organisation events
     */
    getOrganisationEvents(offset: number, limit: number, uuid?: string): Observable<MmbEvent[]> {
        let url = `${environment.domain}${environment.api.organisation.loggedOutEvent}?limit=${limit}&offset=${offset}&organisation=${uuid}`;
        if (this.authService.isAuthenticated$.value) {
            url = `${environment.domain}${environment.api.organisation.loggedInEvent}?limit=${limit}&offset=${offset}&organisation=${uuid}`;
        }

        return this.http.get(url).pipe(
            map((response: HttpResponse<any>): MmbEvent[] => {
                return (<any>response).results;
            })
        );
    }

    /**
     * Get organisation initiatives
     */
    getOrganisationInitiatives(offset: number, limit: number, uuid?: string): Observable<Initiative[]> {
        let url = `${environment.domain}${environment.api.organisation.loggedOutInitiative}?limit=${limit}&offset=${offset}&organisation=${uuid}`;
        if (this.authService.isAuthenticated$.value) {
            url = `${environment.domain}${environment.api.organisation.loggedInInitiative}?limit=${limit}&offset=${offset}&organisation=${uuid}`;
        }

        return this.http.get(url).pipe(
            map((response: HttpResponse<any>): Initiative[] => {
                return (<any>response).results;
            })
        );
    }

    /**
     * User wants to follow organisation
     */
    followOrganisation(orgId: number) {
        const data = {
            organisation: orgId
        };
        const url = `${environment.domain}${environment.api.user.followOrg}`;
        return this.http.post(url, data).pipe(
            map((response: HttpResponse<any>) => {
                return (<any>response);
            })
        );
    }

    /**
     * User wants to unfollow organisation
     */
    unfollowOrganisation(uuid: string) {
        const url = `${environment.domain}${environment.api.user.followOrg}${uuid}`;
        return this.http.delete(url).pipe(
            map((response: HttpResponse<any>) => {
                return (<any>response);
            })
        );
    }

    /**
     * checks if user is following the organisation
     */
    getUserFollowedOrganisations() {
        const url = `${environment.domain}${environment.api.user.followOrg}`;
        return this.http.get(url).pipe(
            map((response: HttpResponse<any>) => {
                return (<any>response);
            })
        );
    }

    /**
     * Create a new job
     */
    createJob(newJob: OrganisationCareer) {
        const url = `${environment.domain}${environment.api.organisation.loggedInCareer}`;
        return this.http.post(url, newJob).pipe(
            map((response: HttpResponse<any>) => {
                return (<any>response);
            })
        );
    }

    /**
     * Create a new event
     */
    createEvent(newEvent: OrganisationEvent) {
        const url = `${environment.domain}${environment.api.organisation.loggedInEvent}`;
        return this.http.post(url, newEvent).pipe(
            map((response: HttpResponse<any>) => {
                return (<any>response);
            })
        );
    }

    /**
     * Create a new initiative
     */
    createInitiative(newInitiative: OrganisationInitiative) {
        const url = `${environment.domain}${environment.api.organisation.loggedInInitiative}`;
        return this.http.post(url, newInitiative).pipe(
            map((response: HttpResponse<any>) => {
                return (<any>response);
            })
        );
    }

    /**
     * Submits organization enquiry form
     */
    submitOrganisationEnquiryForm(enquiryData: any) {
        let url = `${environment.domain}${environment.api.public.publicEnquiry}`;;
        if (this.authService.isAuthenticated$.value) {
            url = `${environment.domain}${environment.api.user.userEnquiry}`;
        }
        return this.http.post(url, enquiryData);
    }

}
