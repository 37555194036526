import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
const errorSrc = 'assets/img/mmb-placeholder.jpg'
@Component({
  selector: 'mmb-web-app-optimized-image',
  templateUrl: './optimized-image.component.html',
  styleUrls: ['./optimized-image.component.scss']
})
export class OptimizedImageComponent implements OnInit {
  @Input() card: any
  @Input() onErrorSrc: string =  errorSrc
  @Input() class: string
  @Output() onImageClicked: EventEmitter<any> = new EventEmitter<any>()
  constructor() {
  }

  ngOnInit() {
  }

  onImageClick() {
    this.onImageClicked.emit()
  }
}
