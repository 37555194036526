import { AuthService } from './../../public/auth.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { INITIATIVE_TYPES } from '../../initiatives/types/initiative-type.enum';
import { Initiative } from '../../initiatives/types/initiative.model';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { Router } from '@angular/router';
import { UserTrackersService } from '../user-trackers.service';
import { RegisterComponent } from '../register/register.component';
import { INTEREST_EVENT_TYPE } from '../types/interest-event-types.enum';
import { APP_MODULE } from '../types/app-module.enum';
import { APP_SOURCE } from '../types/app-source.enum';
import { SharedHelperService } from '../shared-helper.service';

@Component({
  selector: 'mmb-web-app-experience-card',
  templateUrl: './experience-card.component.html',
  styleUrls: ['./experience-card.component.scss']
})
export class ExperienceCardComponent implements OnInit {
  @Input() experience: Initiative;
  @Input() isAuthenticated: boolean;
  @Input() isCommentSectionEnabled: boolean;
  @Input() isActionsEnabled: boolean = true;
  @Input() isCategorizedInSimilar: boolean = true;
  @Input() badge: string;

  @Output() addBookmark: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeBookmark: EventEmitter<any> = new EventEmitter<any>();
  @Output() expressInterest: EventEmitter<any> = new EventEmitter<any>();
  @Output() addComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() commentUpvote: EventEmitter<any> = new EventEmitter<any>();
  @Output() commentDownvote: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router,
    private modalService: NgbModal,
    private authService: AuthService,
    private userTrackersService: UserTrackersService,
    private sharedHelperService: SharedHelperService) { }

  ngOnInit() {
  }

  /**
   * Add bookmark
   */
  onBookmark() {
    this.addBookmark.emit();
    this.onActionTrack('bookmark');
  }

  /**
   * Remove bookmark
   */
  onRemoveBookmark() {
    this.removeBookmark.emit();
    this.onActionTrack('remove-bookmark');
  }

  /**
   * Express interest
   */
  onExpressInterest(ev: any) {
    if (!this.authService.isAuthenticated$.value) {
      // ev.stopPropagation();
      this.sharedHelperService.openRegisterModal();
    } else {
      this.expressInterest.emit();
    }
    if (ev) {
      ev.stopPropagation();
    }
    this.onActionTrack('interest');
  }

  onMoreDetails(ev: any) {
    this.sharedHelperService.openRegisterModal();
    if (ev) {
      ev.stopPropagation();
    }
  }

  /**
   * Maps initiative type from number
   * @param type value
   */
  getInitiativeType(type: number) {
    return INITIATIVE_TYPES.filter((item) => {
      if (item.value == type.toString()) {
        return item.viewValue;
      }
    });
  }

  redirectToDetails(slug: string) {
    this.router.navigate([`/experiences/${slug}`]);
  }

  redirectToOrganisation(slug: string) {
    this.router.navigateByUrl(`/organisation/${slug}/profile`);
  }

  onViewPortChange(ev) {
    if (ev && ev.visible) {
      const data = {
        object_uuid: this.experience.uuid,
        name: `${this.experience.organisation.name} - ${this.experience.title}`,
        slug: this.experience.slug,
        area: this.isCategorizedInSimilar ? 'listing-similar' : 'Experience',
        type: 'In View',
        additional_info: 'experience-listing-logged'
      };

      this.userTrackersService.onImpressions(data).subscribe();
    }
  }

  onActionTrack(buttonName: string) {
    const data = {
      object_uuid: this.experience.uuid,
      name: `${this.experience.organisation.name} - ${this.experience.title} - ${buttonName}`,
      slug: this.experience.slug,
      area: 'Experience',
      type: 'Click',
      additional_info: 'experience-listing-logged'
    };
    this.userTrackersService.onAction(data).subscribe();
  }

  onAddComment(ev) {
    this.addComment.emit(ev);
  }

  onCommentUpvote(ev) {
    this.commentUpvote.emit(ev);
  }

  onCommentDownvote(ev) {
    this.commentDownvote.emit(ev);
  }

  getExpressInterestTooltip() {
    if (this.authService.isAuthenticated$.value) {
      return 'Be considered for early screening';
    }
    return 'Sign up or Log in to use this feature';
  }
}
