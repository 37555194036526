import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../src/environments/environment';
import { Feed, PromoFeed } from '../shared/types/feed.model';

@Injectable({
    providedIn: 'root',
})
export class FeedService {
    constructor(private http: HttpClient) { }

    /**
     * Loads feeds by page
     * @param page page number
     * @param limit page size
     */
    getFeeds(offset: number = 0, limit: number = 15): Observable<Feed[]> {
        const url = `${environment.domain}${environment.api.feed}?limit=${limit}&offset=${offset}&response_type=full`;
        return this.http.get(url).pipe(
            map((response: HttpResponse<any>): Feed[] => {
                return (<any>response).results;
            })
        );
    }

    /**
     * Creates the post for user
     */
    createPost(post: string) {
        const url = `${environment.domain}${environment.api.user.post}`;
        return this.http.post(url, { post: post }).pipe(
            map((response: HttpResponse<any>) => {
                return (<any>response);
            })
        );
    }

    /**
     * Creates the article for user
     */
    createArticle(article: string) {
        const url = `${environment.domain}${environment.api.user.article}`;
        return this.http.post(url, { article: article }).pipe(
            map((response: HttpResponse<any>) => {
                return (<any>response);
            })
        );
    }

    /**
     * Upvote clicked
     * @param ev event
     */
    onCommentUpvoteClicked(url: string, vote: boolean, request: any, isAnswerType?: boolean) {
        // remove vote
        if (request && request.voted && request.voted.vote === 1) {
            this._removeCommentVote(url, request).subscribe((response) => {
                request.votes_total -= 1;
                request.voted = undefined;
            })
            return;
        } else if (request && request.voted && request.voted.vote === -1) {
            // User is updating vote from downvote to upvote
            this._updateCommentVote(url, request, 1, request.voted.uuid).subscribe((response: any) => {
                request.votes_total += 2;
                request.voted = {
                    vote: 1,
                    uuid: response.uuid
                };
            })
            return;
        }

        // add upvote
        this._addCommentVote(url, request, 1).subscribe((response: any) => {
            request.votes_total += 1;
            request.voted = {
                vote: 1,
                uuid: response.uuid
            };
        })
    }

    /**
     * Upvote clicked
     * @param ev event
     */
    onCommentDownvoteClicked(url: string, vote: boolean, request: any, isAnswerType?: boolean) {
        if (request && request.voted && request.voted.vote === -1) {
            // remove vote
            this._removeCommentVote(url, request).subscribe((response) => {
                request.votes_total += 1;
                request.voted = undefined;
            })
            return;
        } else if (request && request.voted && request.voted.vote === 1) {
            // User is updating vote from upvote to downvote
            this._updateCommentVote(url, request, -1, request.voted.uuid).subscribe((response: any) => {
                request.votes_total -= 2;
                request.voted = {
                    vote: -1,
                    uuid: response.uuid
                };
            })
            return;
        }

        // add downvote
        this._addCommentVote(url, request, -1).subscribe((response: any) => {
            request.votes_total -= 1;
            request.voted = {
                vote: -1,
                uuid: response.uuid
            };
        })
    }

    /**
     * Adds a new comment to the request
     */
     addNewComment(url: string, payload: any) {
        return this.http.post(url, payload);
    }

    /**
     * Deletes a comment 
     */
     deleteComment(url: string) {
        return this.http.delete(url);
    }

    /**
     * Adds a new comment to the request
     */
     updateComment(url: string, payload: any) {
        return this.http.put(url, payload);
    }

    /**
    * Upvotes the item of answer item
    */
    private _addCommentVote(url: string, request: any, value: number): Observable<any> {
        let vote = {
            comment: request.id,
            vote: value
        };
        // const url = `${environment.domain}${environment.api.forum.answerVote}`;
        return this.http.post(url, vote);
    }

    /**
     * Deletes the vote of answer item
     * @param request 
     */
    private _removeCommentVote(url: string, request: any): Observable<any> {
        // const url = `${environment.domain}${environment.api.forum.answerVote}${request.voted.uuid}`;
        url = `${url}${request.voted.uuid}`;
        return this.http.delete(url);
    }

    /**
     * Update vote of answer item
     */
    private _updateCommentVote(url: string, request: any, value: number, voteUuid: string): Observable<any> {
        let vote = {
            comment: request.id,
            vote: value
        };
        // const url = `${environment.domain}${environment.api.forum.answerVote}${voteUuid}/`;
        url = `${url}${voteUuid}/`;
        return this.http.put(url, vote);
    }
}
