

// Core Modules
import { Pipe, PipeTransform } from '@angular/core';

/* Pipe that gets the First String in Array of strings. 
    Example usage: user field has Name Lastname data inside, and we just need the first string.
*/
@Pipe({ name: 'firstName' })
export class FirstNamePipe implements PipeTransform {
    transform(value): string {
        if (value) {
            return value.substr(0, value.indexOf(' '));;
        }
        return 'Error fetching details';
    }
}