import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OPPORTUNITY_TYPES } from '../../opportunity/types/opportunity-types.const';
export const ALL_FILTER = 'All';
export const OPPORTUNITY_TYPE_FILTERS = [{
  name: 'All'
},
{
  name: 'Seeking',
  value: 1
},
{
  name: 'Offering',
  value: 2
}];

@Component({
  selector: 'mmb-web-app-community-filters',
  templateUrl: './community-filters.component.html',
  styleUrls: ['./community-filters.component.scss']
})
export class CommunityFiltersComponent implements OnInit {
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() completed: EventEmitter<any> = new EventEmitter<any>();
  @Input() isOpenedInModal: boolean;
  @Input() activeCategory: any;
  @Input() activeType: any;
  @Input() isClosedIncluded: boolean;

  ALL_FILTER = ALL_FILTER;
  OPPORTUNITY_TYPE_FILTERS = OPPORTUNITY_TYPE_FILTERS;
  menuItems: Array<any>;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.menuItems = OPPORTUNITY_TYPES;
  }

  onClose() {
    this.activeModal.close();
  }

  onApplyFilters() {
    this.completed.emit({
      type: this.activeType ? this.activeType.value: null,
      category: this.activeCategory ? this.activeCategory.value: null,
      isClosedIncluded: this.isClosedIncluded
    });
  }

  /**
 * Search updated for opportunity listing
 * @param item Item
 */
  onOpportunityClick(item) {
    this.activeCategory = item;
  }

  onOpportunityTypeClicked(item) {
    this.activeType = item;
  }

  onReset() {
    if (this.activeCategory !== ALL_FILTER) {
      this.activeCategory = null;
    }
  }

  onIncludeClosedChanged(ev) {
    this.isClosedIncluded = !this.isClosedIncluded;
  }

}
