export enum EVENT_INTEREST_VALUE {
    CONTACT = 1,
    INTERESTED = 2,
    HOW_IT_WORKS = 3,
    MORE_DETAILS = 4,
    NOT_INTERESTED = 5,
    NOT_SUITABLE = 6,
    OPEN = 7,
    ORG_MORE_DETAILS = 8,
    REFER = 9,
    RESET_INTEREST = 10,
    CURIOUS = 12
}