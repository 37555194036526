export class Interest {
    user: string;
    email: string;
    username: string;
    uuid_of_notification: string;
    career: string;
    career_slug: string;
    event: string;
    event_slug: string;
    initaitve: string;
    initiative_slug: string;
    opportunity: string;
    opportunity_slug: string;
    source: string;
    source_slug: string;
    event_type: string;
    content: string;
    item_type: string;
    item: string;
    item_slug: string;
    open_refer: string;
    open_live: string;
}