import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

interface MailChimpResponse {
    result: string;
    msg: string;
}

@Injectable()
export class MailchimpService {
    constructor(private http: HttpClient) { }

    subscribeNewsletter(email: string, firstName?: string, lastName?: string): Observable<MailChimpResponse> {
        const mailChimpEndpoint = `${environment.mailchimp.subscribe}&`;
        let params = new HttpParams()
            .set('EMAIL', email);
        if (firstName) {
            params.append('FIRSTNAME', firstName);
        }
        if (lastName) {
            params.append('LASTNAME', lastName);
        }

        const mailChimpUrl = mailChimpEndpoint + params.toString();
        return this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c');
    }
}