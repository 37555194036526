import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MailchimpService } from '../mailchimp.service';

@Component({
  selector: 'mmb-web-app-weekly-newsletter',
  templateUrl: './weekly-newsletter.component.html',
  styleUrls: ['./weekly-newsletter.component.scss']
})
export class WeeklyNewsletterComponent implements OnInit {
  subscriptionForm: UntypedFormGroup = this.fb.group({
    'emailControl': ['', Validators.required]
  });
  constructor(private fb: UntypedFormBuilder, private mailchimpService: MailchimpService,
    private toastr: ToastrService) { }

  ngOnInit() {
  }


  onSubscribe() {
    if (this.subscriptionForm.invalid) {
      this.toastr.error('Please provide a valid email address', 'Error');
      return;
    }
    let email = this.subscriptionForm.controls['emailControl'].value;
    if (email) {
      this.mailchimpService.subscribeNewsletter(email).subscribe((response) => {
        if (response && response.result === 'error') {
          this.toastr.error(response.msg, 'Try again later');
          return;
        }
        if (response && response.msg) {
          this.toastr.success(response.msg);
          return;
        }
        this.toastr.success('Subscribed to the newsletter', 'Success');
      }, (err) => {
        if (err && err.msg) {
          this.toastr.error(err.msg);
          return;
        }
        this.toastr.error('Unable to subscribe to newsletter', 'Try again later');
      });
    }
  }

}
