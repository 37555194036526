import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../public/auth.service';
import { LoginModalComponent } from '../../../shared/login-modal/login-modal.component';
import { RegisterComponent } from '../../../shared/register/register.component';
import { Career } from '../../../shared/types/career.model';
import { ApplyCareerService } from '../../apply/apply.service';
import { CareerService } from '../../careers.service';
import { ModalExternalApplyComponent } from '../../modal-external-apply/modal-external-apply.component';
import { CareerApplication } from '../../types/career-application.model';
import { InviteFormComponent } from '../../../public/invite-others/components/invite-form/invite-form.component';
import { GuidelineInfoComponent } from '../../../shared/guideline-info/guideline-info.component';

export enum STATUS_CANDIDATE {
  PENDING = 15
}

@Component({
  selector: 'mmb-web-app-career-summary-info',
  templateUrl: './career-summary-info.component.html',
  styleUrls: ['./career-summary-info.component.scss']
})
export class CareerSummaryInfoComponent implements OnInit {
  @Input() career: Career;
  slug: string;

  constructor(private careerService: CareerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private applyService: ApplyCareerService,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    this.slug = this.route.snapshot.params.slug;
  }

   /**
   * Returns true if listing type is internal
   */
  isInternal(value: number): boolean {
    return value >= 1 && value <= 3;
  }
  
  /**
   * Returns true if listing type is exteral
   */
  isExternal(value: number): boolean {
    return value >= 4 && value <= 6;
  }

  /**
   * Add career to favorites
   */
  onAddToFavorites(career: Career) {
    if (!this.authService.isAuthenticated$.value) {
      this._openRegisterModal();
    } else {
      this.careerService.addToFavorites(career).subscribe((response) => {
        this.toastr.success('Added to your favourites!', 'Success');
        career.saved = response;
      });
    }
  }

  onOpenShareOptions(ev, shareOptions) {
    if (this.careerService.isCareerExpired(this.career)) {
      return;
    }
    ev.stopPropagation(); 
    shareOptions.open();
  }

  onHowItWorks() {
    let dialogRef = this.modalService.open(GuidelineInfoComponent, {
      size: 'lg',
      centered: true
    });
    dialogRef.componentInstance.closeModal.subscribe(() => {
      dialogRef.close();
    })
  }

  /**
   * Remove career from favorites
   */
  onRemoveFromFavorites(career: Career) {
    if (!this.authService.isAuthenticated$.value) {
      this._openRegisterModal();
    } else {
      const uuid = career.saved.uuid;
      this.careerService.removeFromFavorites(uuid).subscribe(() => {
        this.toastr.success('Removed from favourites!', 'Success');
        career.saved = false;
      });
    }
  }

  isCareerExpired() {
    return this.careerService.isCareerExpired(this.career);
  }

  /**
   * User pressed i am interested button
   */
  onExpressInterest(isTriggeredByApply?: boolean) {
    if (!this.authService.isAuthenticated$.value) {
      this._openRegisterModal();
    } else {
      this.careerService.expressInterest(this.career).subscribe((response: any) => {
        this.career.interested = response;
        // need as api payload is different
        this.career.interested.interested = true;
        if (!isTriggeredByApply) {
          this.toastr.success('Expressed interest', 'Success');
        }
      });
    }
  }

  /**
   * Remove the interest
   */
  onRemoveInterest() {
    if (!this.authService.isAuthenticated$.value) {
      this._openRegisterModal();
    } else {
      this.careerService.removeInterest(this.career).subscribe((response: any) => {
        this.career.interested = null;
        this.toastr.success('Removed the interest', 'Success');
      });
    }
  }

  getShareUrl() {
    return `${window.location.href}`;
  }

  onExternalApply(isAppliedBefore?: boolean) {
    if (!this.authService.isAuthenticated$.value) {
      this._openRegisterModal();
    } else {
      if (!this.career.interested) {
        this.onExpressInterest(true);
      }
      const modalRef = this.modalService.open(ModalExternalApplyComponent, { centered: true, size: 'md' });
      modalRef.componentInstance.orgLogo = this.career.organisation.image_logo_large;
      modalRef.componentInstance.orgName = this.career.organisation.name;
      modalRef.componentInstance.careerId = this.career.id;
      modalRef.componentInstance.redirectUrl = this.career.website_redirect_url;
      modalRef.componentInstance.experienceName = this.career.title;
      modalRef.componentInstance.career = this.career;
      modalRef.componentInstance.submitted.subscribe(() => {
        modalRef.dismiss();
        this.careerService.getCareerDetails(this.slug).pipe(
          map((response) => this.career = response)
        ).subscribe();
      });
    }
  }

  createApplicationPending() {
    if (!this.career.interested) {
      this.onExpressInterest(true);
    }
    if (!this.authService.isAuthenticated$.value) {
      this._openRegisterModal();
    }
    if (!this.career.drafted) {
      const application: CareerApplication = {
        career: this.career.id,
        status_candidate: STATUS_CANDIDATE.PENDING
      };
      this.applyService.submitApplication(application).subscribe(res => {
        this.router.navigate([`/careers/${this.career.slug}/apply`]);
      });
    }
    if (this.career.drafted || this.career.applied) {
      this.router.navigate([`/careers/${this.career.slug}/apply`]);
    }
  }

  /**
   * open invite others component in Modal
   */
  _openInviteModal() {
    const modalRef =  this.modalService.open(InviteFormComponent, {
      centered: true,
      size: 'lg'
    });
    modalRef.componentInstance.isOpenedInModal = true;
    modalRef.componentInstance.career = this.career;
    modalRef.componentInstance.completed.subscribe(() => {
      modalRef.dismiss();
    });
  }
  
  private _openRegisterModal() {
    const modalRef = this.modalService.open(RegisterComponent, { centered: true, size: 'md' });
    modalRef.componentInstance.isOpenedInModal = true;
    modalRef.componentInstance.completed.subscribe(() => {
      modalRef.dismiss();
    });
  }
}
