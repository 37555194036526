import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../events.service';
import { MmbEvent } from '../types/event.model';

@Component({
  selector: 'mmb-web-app-modal-register-event',
  templateUrl: './modal-register-event.component.html',
  styleUrls: ['./modal-register-event.component.scss']
})
export class ModalRegisterEventComponent implements OnInit {
  @Input() orgLogo: string;
  @Input() orgName: string;
  @Input() redirectUrl: string;
  @Input() eventId: number;
  @Input() eventName: string;
  @Input() isRegistedBefore: boolean;
  @Input() event: MmbEvent;
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  externalApplyForm: UntypedFormGroup;
  options = [];
  intentValue: string;
  constructor(public modal: NgbActiveModal, private fb: UntypedFormBuilder, private eventService: EventsService, private toastr: ToastrService) { }

  ngOnInit() {
    this.externalApplyForm = this.fb.group({
      intent: ['', Validators.required],
      yourRequests: [''],
    });
    this.options = [
      {
        value: '1',
        viewValue: 'Register for the event'
      },
      {
        value: '3',
        viewValue: 'Get more information about the event'
      },
      {
        value: '4',
        viewValue: `Contact ${this.orgName}`
      },
      {
        value: '2',
        viewValue: 'General browsing and exploring'
      },
      {
        value: '5',
        viewValue: `Find other ${this.orgName} events`
      }
    ];
  }

  /**
   * On continue to external application
   */
  onContinue() {
    let redirectRef: string = `?ref=${this.createRedirectRef()}&referrer=movemeback.com`;
    let payload = {
      'event': this.eventId,
      'redirect_url': this.redirectUrl,
      'intent': this.externalApplyForm.controls['intent'].value,
      'value': this.externalApplyForm.controls['intent'].value,
      'notes': this.externalApplyForm.controls['yourRequests'].value,
      'redirect_count': '1',
      'redirect_ref': redirectRef
    };

    if (!this.event.applied_external || !this.event.applied_external.uuid) {
      this.eventService.onRegisterEvent(payload).subscribe(() => {
        this._successHandler(redirectRef);
      }, (err) => {
        this._errorHandler(err);
      });
    } else {
      this.eventService.onUpdateRegisterEvent(this.event.applied_external.uuid, payload).subscribe(() => {
        this._successHandler(redirectRef);
      }, (err) => {
        this._errorHandler(err);
      });
    }
  }

  /**
   * Generates redirect reference
   */
  private createRedirectRef() {
    return Date.now() + 'u' + localStorage.getItem('username') + Math.random().toString().substr(2, 20);;
  }

  /**
   * Success handler
   * @param redirectRef 
   */
  private _successHandler(redirectRef: string) {
    this.submitted.emit();
    window.open(`${this.redirectUrl}${redirectRef}`);
  }
  /**
   * Error handler
   * @param err 
   */
  private _errorHandler(err) {
    if (err && err.error && err.error.non_field_errors) {
      this.toastr.error(err.error.non_field_errors);
    } else {
      this.toastr.error('Error occurred while registering the event. Please contact admin!');
    }
  }
}
