import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../public/auth.service';
import { AuthToken } from '../../public/types/auth-token.model';
import PubSub from 'pubsub-js';
import { WEB_APP_EVENTS } from '../../shared/types/app-events.enum';
import { environment } from 'projects/mmb-web-app/src/environments/environment';

@Component({
  selector: 'mmb-web-app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
  loginForm: UntypedFormGroup;
  errorMessage: string[];
  authStateSubscriber$: Subscription;
  environment = environment;
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
  ) {
    if (this.authService.isTokenValid()) {
      // If user is authenticated, navigate to home page
      this.router.navigate(['/']);

    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiry');
    }
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  /**
   * Try to login the user
   */
  onLogin() {
    this.authService.login(this.loginForm.controls['email'].value, this.loginForm.controls['password'].value)
      .subscribe((response: AuthToken) => {
        if (response && response.token) {
          this.submitted.emit();
          this.authService.isAuthenticated();
          // this.authService.saveUserToken(response.token, response.expiry);
          // localStorage.setItem('user', JSON.stringify(response.user));
          // // TODO: Route url contains the requested url
          // // If onboarding completed, take user to redirect Url
          // PubSub.publish(WEB_APP_EVENTS.GET_USER_TRACKER_INFO, { gp: false, routeUrl: this.authService.redirectUrl });
        }
      },
        (err) => {
          if (err.error && err.error.non_field_errors) {
            this.errorMessage = err.error.non_field_errors;
          }
        })
  }

  /**
   * Log out
   */
  onLogout() {
    try {
      this.authService.removeUserToken();
      this.router.navigate(['/login']);
    } catch (ex) {

    }
  }

  onRedirect() {
    this.submitted.emit();
  }

  ngOnDestroy() {
    if (this.authStateSubscriber$) {
      this.authStateSubscriber$.unsubscribe();
    }
  }
}
