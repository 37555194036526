import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mmb-web-app-reaction-icons',
  templateUrl: './reaction-icons.component.html',
  styleUrls: ['./reaction-icons.component.scss']
})
export class ReactionIconsComponent implements OnInit {
  
  constructor() { }

  ngOnInit() {
  }

}
