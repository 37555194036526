export enum PROFILE_STAGES {
    USER_SIGNUP = 1,
    USER_WELCOME = 2,
    USER_INTRODUCE_YOURSELF = 3,
    USER_PERSONA = 4,
    USER_PROFESSIONAL_PROFILE = 5,
    USER_STRENGTHS = 6,
    USER_INTEREST_IN_AFRICA = 7,
    USER_NEEDS = 8,
    USER_REQUESTS_OFFERS = 9,
    USER_COUNTRY_RELATIONS = 10,
    USER_CAREER_ROLES = 11,
    USER_INDUSTRIES = 12,
    USER_CAUSES = 13,
    USER_ORGANISATION_TYPES = 14,
    USER_COMPENSATION = 15,
    USER_REVIEW = 16,
    // custom step for final confirmation
    FINAL_CONFIRMATION = 17
};