import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'mmb-web-app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  getCurrentYear() {
    return moment().year();
  }

}
