import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PubSub } from 'pubsub-js';
import { WEB_APP_EVENTS } from '../types/app-events.enum';
import { environment } from 'projects/mmb-web-app/src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private router: Router, private toastr: ToastrService) { }

  /**
   * Add token 
   * @param request 
   * @param next 
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    if (token && request.url.indexOf('s3.amazonaws.com') === -1 && request.url.indexOf('ipinfo.io') === -1) {
      request = request.clone({
        setHeaders: {
          'Authorization': `token ${token}`
        }
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          if (error.status === 401 && this.router && request.url.indexOf('abstractapi.com') === -1) {
            localStorage.clear();
            PubSub.publish(WEB_APP_EVENTS.TOKEN_EXPIRED);
            this.router.navigate(['/login'], {
              queryParams: {
                redirecturl: location.pathname
              }
            });
            this.toastr.error('Session expired, please login!')
          }
        }
        return throwError(error);
      })
    );
  }
}
