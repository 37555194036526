import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommunityFiltersComponent } from '../community-filters/community-filters.component';
import { MobileFilterListingComponent } from '../mobile-filter-listing/mobile-filter-listing.component';
import { RegisterComponent } from '../register/register.component';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'mmb-web-app-header-extras',
  templateUrl: './header-extras.component.html'
})
export class HeaderExtrasComponent implements OnInit {
  @Input() title: string;
  @Input() menu: string;
  @Input() subtitle: string;
  @Input() icon: string;
  @Input() isDark: boolean;
  @Input() hidemenu: boolean;
  @Input() isAuthenticated: boolean;
  @Input() entityType: string;
  @Input() navButton: {
    text: string,
    isComingSoon: boolean,
    isRestrictedToLoggedInUser: boolean
  };
  @Input() isUserLoggedIn: boolean;
  @Input() showSearchBar: boolean;
  @Input() showNavBar: boolean;
  @Input() navItems: any;
  @Input() count = 0;
  @Input() navButtons: any;
  @Input() selectedNavItemIndex: number = 0;
  @Input() containerBgClass: string;
  @Input() filters: any;
  @Output() iconButtonClicked: EventEmitter<any> = new EventEmitter<string>();
  @Output() searchStringEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() headerButtonEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectTabEvent: EventEmitter<object> = new EventEmitter<object>();
  @Output() filtersApplied: EventEmitter<any> = new EventEmitter<any>();
  @Output() filtersClicked: EventEmitter<any> = new EventEmitter<any>();

  stringInput: string;
  activeIndex: number = 0;
  menuCollapsed: boolean = true;
  searchObserver$: Subject<any> = new Subject<any>()
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.searchObserver$.pipe(
      debounceTime(500),
      tap(() => { 
        this.searchStringEvent.emit(this.stringInput);
      })
    ).subscribe();
  }

  onSearchFieldChanges() {
    this.searchObserver$.next({});
  }

  /** Generic function for header btn emit. */
  headerButtonClicked() {
    this.headerButtonEvent.emit();
  }
  /**
   * Used for adding active class to the tab that is selected
   * @param navItem : item that is selected from the nav-tabs
   * @param i : index of selected item
   */
  selectTab(navItem, i) {
    if (!navItem.disabled) {
      this.activeIndex = i;
      this.selectTabEvent.emit(navItem);
    }
  }

  onButtonIconClick(button: any, ev: any) {
    this.iconButtonClicked.emit(button);
    if (ev) {
      ev.stopPropagation();
    }
  }

  onSearchFocus(ev) {
    if (!this.isUserLoggedIn) {
      this._openRegisterModal();
      if (ev) {
        ev.currentTarget.blur();
      }
      return;
    }
  }

  isDisabled(item) {
    return item.disabled ? 'Coming soon' : '';
  }

  getTooltip(button) {
    if (button && button.isComingSoon) {
      return 'Coming soon';
    }
    return '';
  }

  getContainerBgClass() {
    if (this.containerBgClass) {
      return this.containerBgClass;
    }
    if (this.isDark) {
      return 'bg-dark-black--200 text-white';
    }
    return 'bg-secondary text-white';
  }

  private _openRegisterModal() {
    const modalRef = this.modalService.open(RegisterComponent, { centered: true, size: 'md' });
    modalRef.componentInstance.isOpenedInModal = true;
    modalRef.componentInstance.completed.subscribe(() => {
      modalRef.dismiss();
    });
  }

  filterListing() {
    const modalRef = this.modalService.open(MobileFilterListingComponent, { centered: true, size: 'md' });
    modalRef.componentInstance.isOpenedInModal = true;
    modalRef.componentInstance.title = `${this.title} Filter`;
    modalRef.componentInstance.filters = this.filters;
    modalRef.componentInstance.entityType = this.entityType;
    modalRef.componentInstance.applied.subscribe((ev) => {
      this.filtersApplied.emit(ev);
      modalRef.dismiss();
    });
  }

  filterComunity() {
    this.filtersClicked.emit();
  }
}
