import { Component, EventEmitter, Input, OnInit, Output, ViewChild, Renderer2, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../public/auth.service';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { RegisterComponent } from '../register/register.component';
import { User } from '../../profile/types/user.model';
import { ProfileService } from '../../profile/profile.service';

@Component({
  selector: 'mmb-web-app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnChanges {
  @Output() addComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() upvote: EventEmitter<any> = new EventEmitter<any>();
  @Output() downvote: EventEmitter<any> = new EventEmitter<any>();

  @Input() isAuthenticated: boolean;
  @Input() totalComments: number;
  @Input() comments: any[];
  @Input() profilePic: string;

  @Input() focusFlag = false;

  comment: string;
  updatedComment: string;
  isCommentSectionOpen: boolean = true;
  defaultCommentsDisplayCount: number = 2;
  isViewAllCommentsActive: boolean;
  isCommentClick: boolean = false;
  user: User;
  editCommentMode: boolean = false;

  @ViewChild('addNewCommentInput', { static: false }) textarea: ElementRef;

  constructor(private authService: AuthService, private modalService: NgbModal, private renderer: Renderer2, private profileService: ProfileService) {
    this.authService.user$.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['focusFlag'] && this.focusFlag) {
      this.textarea.nativeElement.focus();
    }
  }

  get sortedComments() {
    if (Array.isArray(this.comments)) {
      return [...this.comments].sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
    }
  }

  isCurrentUsersComment(comment: any): boolean {
    return this.user && comment.user && comment.user.uuid === this.user.uuid;
  }

  getMultilineComment(comment) {
    return comment.replace(/(\r\n|\r|\n)/g, '<br>');
  }

  getTooltipTextIfLoggedOut() {
    if (!this.authService.isAuthenticated$.value) {
      return 'Sign up or Log in to use this feature';
    }
    return '';
  }

  onReply(input) {
    input.focus();
  }

  /**
   * Add new comment for question
   */
  onAddNewComment() {
    this.addComment.emit(this.comment);
    this.comment = '';
  }

  /**
   * Deletes the comment
   */
  onDeleteComment(comment: any) {
    this.deleteComment.emit(comment);
    this.comment = '';
  }

  /**
   * Deletes the comment
   */
  onUpdateComment(comment: any, commentDiv: any) {
    this.updateComment.emit({ comment, commentDiv });
  }

  onEditComment(commentDiv: any) {
    commentDiv.querySelector('#comment').classList.add('mmb-display-none');
    commentDiv.querySelector('#commentTextArea').classList.remove('mmb-display-none');
    commentDiv.querySelector('#cancelCommentButton').classList.remove('mmb-display-none');

  }

  onCancelEditMode(commentDiv: any) {
    commentDiv.querySelector('#comment').classList.remove('mmb-display-none');
    commentDiv.querySelector('#cancelCommentButton').classList.add('mmb-display-none');
    commentDiv.querySelector('#commentTextArea').classList.add('mmb-display-none');
  }

  onCommentInputClicked() {
    if (!this.authService.isAuthenticated$.value) {
      this._openRegisterModal();
    }
    this.isCommentClick = true;
  }

  removeFocus() {
    this.isCommentClick = false;
  }

  onUpvote(comment: any) {
    this.upvote.emit(comment);
  }

  onDownvote(comment: any) {
    this.downvote.emit(comment);
  }

  private _openRegisterModal() {
    const modalRef = this.modalService.open(RegisterComponent, {
      centered: true,
      size: 'md'
    });
    modalRef.componentInstance.isOpenedInModal = true;
    modalRef.componentInstance.completed.subscribe(() => {
      modalRef.dismiss();
    });
  }

  onTextareaInput(): void {
    const textarea = this.textarea.nativeElement;
    textarea.style.height = 'auto'; // set height to auto to measure content
    textarea.style.height = textarea.scrollHeight + 'px'; // set the height to the measured content height
  }
}
