import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'mmb-web-app-guideline-info',
  templateUrl: './guideline-info.component.html',
  styleUrls: ['./guideline-info.component.scss']
})
export class GuidelineInfoComponent implements OnInit {
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  onCloseGuidelineModal() {
    this.closeModal.emit();
  }
  

}
