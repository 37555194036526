import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mmb-web-app-how-requests-work',
  templateUrl: './how-requests-work.component.html',
  styleUrls: ['./how-requests-work.component.scss']
})
export class HowRequestsWorkComponent implements OnInit {
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  onCloseModal() {
    this.closeModal.emit();
  }

  onLearnMore() {
    window.open(`https://${window.location.host}/insight/resources/how-to-write-a-great-support-request`, '_blank');
  }
  

}
