export const CAREER_TYPES = [{
    value: '1',
    viewValue: 'Full Time'
}, {
    value: '2',
    viewValue: 'Part Time'
}, {
    value: '3',
    viewValue: 'Contract'
}, {
    value: '4',
    viewValue: 'Freelance Service'
}, {
    value: '5',
    viewValue: 'Advisor'
}, {
    value: '6',
    viewValue: 'Board Position'
}, {
    value: '7',
    viewValue: 'Internship'
}, {
    value: '8',
    viewValue: 'Placement'
}, {
    value: '9',
    viewValue: 'Volunteer'
}, {
    value: '10',
    viewValue: 'Representative'
}];