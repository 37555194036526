import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ForumService } from '../../forum/forum.service';
import { AuthService } from '../../public/auth.service';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'mmb-web-app-create-answer-panel',
  templateUrl: './create-answer-panel.component.html',
  styleUrls: ['./create-answer-panel.component.scss']
})
export class CreateAnswerPanelComponent implements OnInit {
  @ViewChild('answerInput', { static: false }) answerInput: ElementRef;
  @Input() isDarkThemeEnabled: boolean;
  @Input() questionId: number;
  @Input() isAuthenticated: boolean;
  @Input() set enableFocus(value: any) {
    // set the focus on item
    if (value) {
      this.answerInput.nativeElement.focus();
    }
  }

  createAnswerForm: UntypedFormGroup = this.fb.group({
    answer: ['', [Validators.required]]
  });
  constructor(private fb: UntypedFormBuilder, private authService: AuthService,
    private modalService: NgbModal, private forumService: ForumService,
    private toastr: ToastrService) { }

  ngOnInit() {
  }

  onAnswerInputClicked() {
    if (!this.authService.isAuthenticated$.value) {
      this._openRegisterModal();
    }
  }

  onSubmitAnswer() {
    if (this.createAnswerForm.invalid) {
      this.toastr.error('Plase provide the answer to the question', 'Error');
      return;
    }
    // Send the answer to api
    this.forumService.onSubmitAnswer(this.questionId, this.createAnswerForm.controls['answer'].value).subscribe(() => {
      this.toastr.success('Answered the question', 'Success');
    }, (err) => this.toastr.error('Some error occurred while trying to save answer. Please retry or contact admin if issue persists', 'Error'));
  }

  private _openRegisterModal() {
    const modalRef = this.modalService.open(RegisterComponent, {
      centered: true,
      size: 'md'
    });
    modalRef.componentInstance.isOpenedInModal = true;
    modalRef.componentInstance.completed.subscribe(() => {
      modalRef.dismiss();
    });
  }
}
