import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appAutoResize]'
})
export class AutoResizeDirective implements OnInit {

  private initialHeight: string;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.initialHeight = this.el.nativeElement.style.height;
    this.adjust();
  }

  @HostListener('input')
  onInput(): void {
    this.adjust();
  }

  @HostListener('keydown.enter')
  onEnter(): void {
    this.reset();
  }

  private adjust(): void {
    const textarea = this.el.nativeElement;
    textarea.style.height = this.initialHeight;
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  private reset(): void {
    const textarea = this.el.nativeElement;
    textarea.style.height = this.initialHeight;
    textarea.value = '';
    textarea.blur();
  }
}
