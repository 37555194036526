import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavbarService, NavbarType } from './navbar.service';
import { AuthService } from '../../public/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProfileService } from '../../profile/profile.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

export enum NAV_MENU_MODULE {
  FEED,
  ABOUT,
  OPPORTUNITIES,
  COMMUNITY,
  INSIGHT,
  APPS,
  PROFILE
};

export enum MOBILE_NAV_MENU_MODULE {
  LOGIN,
  BECOME_MEMBER,
  ABOUT,
  CAREERS,
  EXPERIENCES,
  EVENTS,
  REQUESTS,
  FORUM,
  NEWSROOM,
  ORGANISATIONS,
  PROJECTS,
};

export const NAV_MENU_MODULE_ITEMS = {
  ABOUT: ['/about', '/partners'],
  FEED: ['/feed'],
  OPPORTUNITY: ['/careers', '/experiences', '/events'],
  COMMUNITY: ['/requests', '/forum', '/projects'],
  INSIGHT: ['/news', '/pulse', '/community', '/updates', '/insights'],
  APPS: ['/contact', '/organisations', '/referral'],
  PROFILE: ['/profile']
}
export const MOBILE_NAV_MENU_MODULE_ITEMS = {
  LOGIN: ['/login'],
  BECOME_MEMBER: ['/join'],
  ABOUT: ['/about'],
  CAREERS: ['/careers'],
  EXPERIENCES: ['/experiences'],
  EVENTS: ['/events'],
  REQUESTS: ['/requests'],
  FORUM: ['/forum'],
  PROJECTS: ['/projects'],
  NEWSROOM: ['/news'],
  ORGANISATIONS: ['/organisations'],
}

@Component({
  selector: 'mmb-web-app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  NAV_MENU_MODULE = NAV_MENU_MODULE;
  avatar = '';
  localStorageUser: any;
  navbarType = this.navbarService.navbarType.value;
  isUserLoggedIn: boolean = false;
  navbarCollapsed: boolean;
  isAuthScreenActive: boolean;
  isUserOnboardingActive: boolean;
  employeeInOrgs: any[];

  @Output() navbarTypeUpdated: EventEmitter<string> = new EventEmitter<string>();
  constructor(private navbarService: NavbarService,
    private authService: AuthService,
    private router: Router,
    private profileService: ProfileService,
    private location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService) {
    // check if user is on 'organisations' slug
    router.events.subscribe(val => {
      this.isUserOnboardingActive = this.location.path().indexOf('/welcome') !== -1 || this.location.path().indexOf('/verify-email') !== -1 || this.location.path().indexOf('/verify-phone-number') !== -1 || this.location.path().indexOf('/change-email') !== -1 || this.location.path().indexOf('/change-phone') !== -1;
    });

    this.navbarService.isAuthScreenActive$.subscribe((value) => {
      this.isAuthScreenActive = value;
    })
  }

  public showMenu = false;

  public menu_desktop = [
    {
      name: 'About',
      hideOnLoggedIn: true,
      link: "/about",
      subMenu: [
        {
          name: 'About',
          link:'/about',
        },
        {
          name: 'Partners',
          link:'/partners',
        },
      ]
    },
    {
      name: 'Opportunity',
      link: "/careers",
      subMenu: [
        {
          name: 'Careers',
          link:'/careers',
        },
        {
          name: 'Experiences',
          link:'/experiences',
        },
        {
          name: 'Events',
          link:'/events',
        },
        {
          name: 'Contracts',
          link:'/contracts',
        },
      ]
    },
    {
      name: 'Community',
      link: "/requests",
      subMenu: [
        {
          name: 'Requests',
          link:'/requests',
        },
        {
          name: 'Forum',
          link:'/forum',
        },
        {
          name: 'Projects',
          link:'/projects',
        },
        {
          name: 'Refer new members',
          link:'/referral',
        },
      ]
    },
    {
      name: 'Insight',
      link: "/insight",
      subMenu: [
        {
          name: 'News',
          link:'/insight/news',
        },
        {
          name: 'Pulse',
          link:'/insight/pulse',
        },
        {
          name: 'Stories',
          link:'/insight/stories',
        },
        {
          name: 'Insights',
          link:'/insight/insights',
        },
        {
          name: 'Updates',
          link:'/insight/updates',
        },
        {
          name: 'Resources',
          link:'/insight/resources',
        },
        {
          name: 'Blog',
          externalLink:'https://www.movemeback.com/community',
        },
      ]
    },
    {
      name: 'Organisations',
      link:'/organisations',
    },
    {
      icon: 'ri-apps-2-line',
      subMenu: [
        {
          name: 'Contact',
          link:'/contact',
        },
        {
          name: 'FAQ',
          link:'/faq/members',
        }
      ]
    },
  ]

  ngOnInit(): void {
    // check if user is authenticated
    this.authService.isAuthenticated$.subscribe((value: boolean) => {
      this.isUserLoggedIn = value;
      if (value) {
        // get user avatar
        let user = this.localStorageUser = JSON.parse(localStorage.getItem('user'));
        this.avatar = user ? user.profile_image : '';
      }
    });

    // subscribe to profile changes
    this.profileService.userProfile$.subscribe((response) => {
      if (response) {
        this.avatar = response.profile_image;
      }
    });
    
    this.authService.orgEmployee$.subscribe((value) => {
      this.employeeInOrgs = value;
    })
  }


  isNavMenuActive(item: NAV_MENU_MODULE) {

    switch (item) {
      case NAV_MENU_MODULE.FEED:
        return this.isValueExistsInArray(this.location.path(false), NAV_MENU_MODULE_ITEMS.FEED);
      case NAV_MENU_MODULE.ABOUT:
        return this.isValueExistsInArray(this.location.path(false), NAV_MENU_MODULE_ITEMS.ABOUT);
      case NAV_MENU_MODULE.OPPORTUNITIES:
        return this.isValueExistsInArray(this.location.path(false), NAV_MENU_MODULE_ITEMS.OPPORTUNITY);
      case NAV_MENU_MODULE.COMMUNITY:
        return this.isValueExistsInArray(this.location.path(false), NAV_MENU_MODULE_ITEMS.COMMUNITY);
      case NAV_MENU_MODULE.INSIGHT:
        return this.isValueExistsInArray(this.location.path(false), NAV_MENU_MODULE_ITEMS.INSIGHT);
      case NAV_MENU_MODULE.APPS:
        return this.isValueExistsInArray(this.location.path(false), NAV_MENU_MODULE_ITEMS.APPS);
      case NAV_MENU_MODULE.PROFILE:
        return this.isValueExistsInArray(this.location.path(false), NAV_MENU_MODULE_ITEMS.PROFILE);
    }
  }

  openMenu() {
    this.showMenu = !this.showMenu;
  }

  isValueExistsInArray(value: string, items: string[]) {
    for (let item of items) {
      if (value.includes(item)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Logout
   */
  onLogout() {
    this.navbarCollapsed = false;
    this.authService.logout().subscribe(
      (response) => {
        this.authService.removeUserToken();
        localStorage.clear();
        this.authService.user$.next(null);
        this.profileService.userProfile$.next(null);
        this.router.navigate(['/login']);
      }, 
      (error) => {
        this.toastr.error('Something went wrong. Please try again later.');
      }
    );
  }

  onRedirectToLogin() {
    this.navbarCollapsed = false;
    let currentPath = this.route.snapshot['_routerState'].url;
    this.router.navigate(['/login'], {
      queryParams: {
        redirecturl: currentPath
      }
    })
  }

  // Route changed - close navbar
  onRouteChange() {
    this.navbarCollapsed = false;
  }

  closeNav() {
    this.navbarCollapsed = false;
  }
}
