import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../public/auth.service';
import { Organisation } from '../../organisation/types/organisation.model';
import { OrganisationService } from '../../organisation/organisation.service';
import { Router } from '@angular/router';
import { ProfileService } from '../../profile/profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mmb-web-app-org-navbar',
  templateUrl: './org-navbar.component.html',
  styleUrls: ['./org-navbar.component.scss']
})
export class OrgNavbarComponent implements OnInit {
  employeeInOrgs: Organisation[];
  selectedOrganisation: Organisation;

  constructor(private authService: AuthService, private orgService: OrganisationService,
    private router: Router, private profileService: ProfileService, private toastr: ToastrService) { }

  ngOnInit() {
    this.authService.orgEmployee$.subscribe((employee: Organisation[]) => {
      this.employeeInOrgs = employee;
    });

    this.orgService.selectedOrganisation$.subscribe((response) => {
      this.selectedOrganisation = response;
    })
  }

    /**
   * Logout
   */
    onLogout() {
      this.authService.logout().subscribe(
        (response) => {
          this.authService.removeUserToken();
          localStorage.clear();
          this.authService.user$.next(null);
          this.profileService.userProfile$.next(null);
          window.location.href = '/login';
        }, 
        (error) => {
          this.toastr.error('Something went wrong. Please try again later.');
        }
      );
    }

  redirectToUserPortal() {
    this.authService.isOrgViewActive$.next(false);
    this.router.navigate(['/' ], { queryParams: {
      'org-switch': true
    } });
  }

  getRouteLink(organisation: Organisation) {
    if (!organisation) {
      return null;
    }
    return `/organisation/landing/${organisation.uuid}`;
  }
}
