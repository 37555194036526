import { InjectionToken } from '@angular/core';
import { FormGroup } from '@angular/forms';

export type FormValidationFunction = 
          (form: FormGroup, control: string, error: string) => boolean;

export const FORM_ERROR_FN = new InjectionToken<FormValidationFunction>('FORM_ERROR_FN');

export function getFormError(form: FormGroup, control: string, error: string): boolean {
  const formControl = form.get(control);
  return formControl ? (formControl.touched || formControl.dirty) && formControl.hasError(error) : false;
}