export class ProfileTracker {
    uuid: string;
    user: number;
    stage: number;
    status: number;
    completion: number;
}

export enum PROFILE_TRACKER_STAGE_STATUS {
    PENDING = 1,
    STARTED = 2,
    COMPLETED = 3
}