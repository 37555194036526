import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mmb-web-app-save-application-confirmation',
  templateUrl: './save-application-confirmation.component.html',
  styleUrls: ['./save-application-confirmation.component.scss']
})
export class SaveApplicationConfirmationComponent implements OnInit {
  @Output() exitAfterSave: EventEmitter<any> = new EventEmitter<any>();
  constructor(public modal: NgbActiveModal, private router: Router) { }

  ngOnInit() {
  }

  onExitAfterSave() {
    this.modal.close();
    this.exitAfterSave.emit();
  }

  /**
   * Cance application and go back to careers
   */
  onExit() {
    this.modal.close();
    this.router.navigateByUrl('/careers');
  }
}
