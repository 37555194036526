import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
import { ForumService } from '../../forum/forum.service';
import { FeedService } from '../../feed/feed.service';
import { OpportunityService } from '../../opportunity/opportunity.service';
import { OPPORTUNITY_CATEGORIES } from '../../opportunity/types/opportunity-categories.const';
import { OPPORTUNITY_TYPES } from '../../opportunity/types/opportunity-types.const';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CK_EDITOR_SETTINGS } from '../types/_ck-editor-settings.const';

export enum CREATE_POST_MODAL_VIEW {
  START_POST,
  ASK_QUESTION,
  CREATE_REQUEST
}
@Component({
  selector: 'mmb-web-app-modal-create-post',
  templateUrl: './modal-create-post.component.html',
  styleUrls: ['./modal-create-post.component.scss']
})
export class ModalCreatePostComponent implements OnInit {
  @Input() activeMode = CREATE_POST_MODAL_VIEW.START_POST;

  @Output() addSuccess: EventEmitter<any> = new EventEmitter<any>();
  editorConfig: any = CK_EDITOR_SETTINGS;
  availableModes = CREATE_POST_MODAL_VIEW;
  user: {
    image: string;
    name: string;
    professionalTitle: string;
  };
  postForm = this.fb.group({
    // title: ['', Validators.required],
    request: ['', Validators.required],
  });
  Editor = ClassicEditor;

  questionForm = this.fb.group({
    title: ['', Validators.required],
    request: ['', Validators.required],
    tags: [[]]
  });

  requestForm = this.fb.group({
    title: ['', Validators.required],
    request: ['', [Validators.required, Validators.minLength(100)]],
    opportunityType: [null, Validators.required],
    opportunityCategory: [null, Validators.required],
    tags: [[]]
  });
  opportunityTypes = OPPORTUNITY_TYPES;
  opportunityCategories = OPPORTUNITY_CATEGORIES;
  linkPreviewData: any;
  activeStepRequestFlow: number = 1;

  constructor(
    public modal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private feedService: FeedService,
    private toastrService: ToastrService,
    private forumService: ForumService,
    private toastr: ToastrService,
    private opportunityService: OpportunityService) {
    this.editorConfig.placeholder = 'Start typing...';
  }

  ngOnInit() {
    let user = JSON.parse(<any>localStorage.getItem('user'));
    this.user = {
      image: user.profile_image,
      name: `${user.first_name} ${user.last_name}`,
      professionalTitle: user.credentials ? user.credentials.professional_title : ''
    };

    this.postForm.controls['request'].valueChanges.pipe(
      debounceTime(1000)
    ).subscribe((value) => {
      var matches = value.match(/\bhttps?:\/\/\S+/gi);
      if (matches) {
        this.linkPreviewData = null;
        for (let item of matches) {
          this.getLinkPreviewContent(item);
        }
      }
    })
  }

  /**
   * Create the post
   */
  onAdd() {
    let post: string = this.postForm.controls['request'].value;
    if (post.trim() === '') {
      this.toastrService.error('Please enter the post update before proceeding...', 'Error');
      return;
    }
    this.feedService.createPost(post).subscribe((response) => {
      this.toastrService.success('Created the post.', 'Success');
      this.addSuccess.emit({
        item: response,
        item_type: 'post'
      });
    }, (err) => {
      this.toastrService.error('Some error occured while creating the post', 'Error');
    });
  }

  /**
   * Adds question
   */
  onAddQuestion() {
    // this.loading = true;
    const newQuestion = {
      title: this.questionForm.value.title,
      question: this.questionForm.value.request,
      tags: this.questionForm.value.tags
    }
    this.forumService.postNewQuestion(newQuestion).subscribe((resp) => {
      // this.loading = false;
      this.toastr.success('Question is posted on the community.', 'Success');
      this.modal.dismiss();
      this.addSuccess.emit();
    },
      (error) => {
        this.toastr.error('Something went wrong');
      });
  }

  /**
   * Creates request
   */
  onCreateRequest() {
    const newQuestion = {
      title: this.requestForm.value.title,
      opportunity: this.requestForm.value.request,
      // opportunity type and category are swapped between FE and BE
      // Need to resolve this mismatch
      opportunity_type: this.requestForm.value.opportunityCategory,
      category: this.requestForm.value.opportunityType,
      status_user: 2 // live
    };

    this.opportunityService.postNewQuestion(newQuestion).subscribe((resp) => {
      this.toastr.success('You have created a new opportunity');
      this.modal.dismiss();
      this.addSuccess.emit();
    },
      (error) => {
        this.toastr.error('Something went wrong');
      }
    );
  }

  getTitle() {
    if (this.activeMode === CREATE_POST_MODAL_VIEW.ASK_QUESTION) {
      return 'Ask a Question';
    } else if (this.activeMode === CREATE_POST_MODAL_VIEW.CREATE_REQUEST) {
      return 'Create Request';
    }
    return 'Start a post';
  }

  onEnableAskQuestionView() {
    this.activeMode = CREATE_POST_MODAL_VIEW.ASK_QUESTION;
  }

  onEnableAskRequestView() {
    this.activeMode = CREATE_POST_MODAL_VIEW.CREATE_REQUEST;
  }

  onEnablePostView() {
    this.activeMode = CREATE_POST_MODAL_VIEW.START_POST;
  }

  getLinkPreviewContent(url: string) {
  }

  onFormReset() {
    this.requestForm.reset();
  }

  onNextStep() {
    if (this.activeMode === CREATE_POST_MODAL_VIEW.CREATE_REQUEST) {
      if (this.requestForm.controls['title'].invalid) {
        this.toastr.error('Title is required');
        return;
      }
      if (this.requestForm.controls['request'].value.length < 100) {
        this.toastr.error('Minimum of 100 characters required');
        return;
      }
    }
    this.activeStepRequestFlow = 2;
  }

  onBackStep() {
    this.activeStepRequestFlow = 1;
  }
}