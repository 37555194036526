import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mmb-web-app-accordion-container',
  templateUrl: './accordion-container.component.html',
  styleUrls: ['./accordion-container.component.scss']
})
export class AccordionContainerComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() isOpen: boolean;
  constructor() { }

  ngOnInit() {
  }

}
