import { AfterContentInit, AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute, Event as RouterEvent, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NavbarService, NavbarType } from './shared/navbar/navbar.service';
import { AuthService } from './public/auth.service';
import { SharedService } from './shared/shared.service';
import { USER_ROLE_OPTIONS } from './shared/types/user-roles.const';
import { InterestService } from './shared/interest.service';
import { Interest } from './shared/types/interest.model';
import { ProfileService } from './profile/profile.service';
import { ToastrService } from 'ngx-toastr';
import { PROFILE_STAGES } from './profile/types/profile-stages.model';
import { ProfileTracker, PROFILE_TRACKER_STAGE_STATUS } from './profile/types/profile-tracker.model';
import { PubSub } from 'pubsub-js';
import { Subscription } from 'rxjs';
import { WEB_APP_EVENTS } from './shared/types/app-events.enum';
import { SharedHelperService } from './shared/shared-helper.service';
import { UserTrackersService } from './shared/user-trackers.service';
import { environment } from '../environments/environment';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

window.onunload = function () {
  sessionStorage.setItem('mmb.page.scrollY', <any>window.scrollY);
}

window.onload = function () {
  var scrollY = parseInt(sessionStorage.getItem('mmb.page.scrollY'));
  if (!isNaN(scrollY)) {
    window.scroll(0, scrollY);
  }
}

export const COOKIE_EXPIRY_DAYS = 5;
export const ALLOWED_ANONYMOUS_USER_RELOADS = 3;
export const TIMER_SETTINGS = {
  ANALYTICS_TIMER: 20000, // send ping every 20 seconds
  SLOW_PING_AFTER: 180000, // send slow pings after 3 minutes = 180 seconds
  SLOW_PING_TIMER: 60000, // slow ping time every 1 minute
  USER_AWAY_PING_AFTER: 900000, // 15 minutes
  USER_AWAY_PING_TIMER: 300000, // 5 minutes
  IDLE_PING_AFTER: 3600000, // 1 hour
  IDLE_PING_TIMER: 1200000 // 20 mins
}

@Component({
  selector: 'mmb-web-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, AfterContentInit, AfterViewInit {
  isLoggedIn: boolean = false;
  navbarType: string;
  query: Interest = new Interest();
  isGoogleProfile: boolean;
  isAuthenticated: boolean;
  public showOverlay = true;
  isPageLoading: boolean = true;
  postLoginRedirectUrl: string;
  postLoginAction: string;
  isOrgUser: boolean = false;

  private _trackerSubsription: Subscription;
  private _routerEventsSubscription;

  private _pageEntryAnalyticsQuickPingSubscription: any;
  private _pageEntryAnalyticsSlowPingSubscription: any;
  private _pageEntryAnalyticsAwayPingSubscription: any;
  private _pageEntryAnalyticsIdlePingSubscription: any;
  private _analyticsTimerCount: number = 0;

  constructor(private router: Router,
    private navbarService: NavbarService,
    private authService: AuthService,
    private sharedService: SharedService,
    private interestService: InterestService,
    private activatedRoute: ActivatedRoute,
    private profileService: ProfileService,
    private toastr: ToastrService, private route: ActivatedRoute,
    private sharedHelperService: SharedHelperService,
    private userTrackersService: UserTrackersService,
    private modalService: NgbModal,
    @Inject(DOCUMENT) private document: Document) {
    router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      // check if auth screen is active
      this.navbarService.isAuthScreenActive$.next(
        event.url.indexOf('/login') !== -1 || event.url.indexOf('/join') !== -1 || event.url.indexOf('/forgot-password') !== -1
        || event.url.indexOf('/welcome') !== -1 || event.url.indexOf('/email/verification') !== -1 || event.url.indexOf('/verify-email') !== -1
        || event.url.indexOf('/change-email') !== -1 || event.url.indexOf('/verify-phone-number') !== -1 || event.url.indexOf('/change-phone') !== -1
      );


      // details page
      // if (event.url.indexOf('/careers/') !== -1 || event.url.indexOf('/events/') !== -1 || event.url.indexOf('/initiatives/') !== -1) {
      //   this.navbarService.setNavbarType(NavbarType.LIGHT);
      // }

      this.activatedRoute.queryParams.subscribe((queryParams) => {
        this.postLoginRedirectUrl = queryParams['redirecturl'];
        this.postLoginAction = queryParams['action'];
      })

      this.authService.isOrgViewActive$.subscribe((value) => {
        this.isOrgUser = value;
      })
    });

    // Get user roles config
    // this.sharedService.getUserRolesConfiguration();

    this._subscribeToTokenExpiredEvent();

    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
      if (event instanceof NavigationEnd) {
        this._setInterestIfApplicable()

        if (!this.profileService.profileTracker$.value) {
          this._setUserStateByTracker();
        }

        if (event.url.indexOf('/careers') !== -1 || event.url.indexOf('/events') !== -1 || event.url.indexOf('/experiences') !== -1 ||
          event.url.indexOf('/requests') !== -1 || event.url.indexOf('/forum') !== -1) {
          // update cookie information
          this._setNavigationCookie(event.url);
        }
      }
    })
  }

  ngOnInit() {
    this.sharedHelperService.getSiteSeoData();

    this.authService.isAuthenticated();

    // check if user is logged in or not
    this.authService.isAuthenticated$.subscribe(res => {
      this.isAuthenticated = res;
      if (res) {
        // get employee accounts
        this.authService.getUserEmployeeData().subscribe();

        this.sharedService.getOnboardingStagesInfo().subscribe((response) => {
          this.sharedService.onboardingTrackerStages$.next(response);
        })
      }
    });

    // subscribe to tracker info
    this._subscribeToTrackerInfo();
    this._subscribeToRouterEvents();

    // if page is not internal server then load reference types
    // get all reference types
    if (window.location.href.indexOf('internal-error') === -1) {
      this.sharedService.getReferenceTypes().subscribe((response) => {
        this.sharedService.referenceTypes$.next(response);
      });
    }
  }

  ngAfterContentInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._loadGtm(window, document, 'script', 'dataLayer', environment.gtmId);

      // Hot jar load
      if (environment.hjSiteId) {
        ((h, o, t, j, a, r) => {
          h.hj =
            h.hj ||
            /* tslint:disable:only-arrow-functions */
            function () {
              (h.hj.q = h.hj.q || []).push(arguments);
            };
          h._hjSettings = { hjid: environment.hjSiteId, hjsv: 6 };
          a = o.getElementsByTagName('head')[0];
          r = o.createElement('script');
          r.defer = true;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window as any, this.document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
      }

      // Hot jar load
      if (environment.production) {
        this._loadHubspot();
      }

      this._getIpAddress();
    }, 2000);
  }

  private _loadHubspot() {
    let node = document.createElement('script');
    node.src = '//js-eu1.hs-scripts.com/25923336.js';
    node.type = 'text/javascript';
    node.id = 'hs-script-loader';
    node.async = true;
    node.defer = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  private _loadGtm(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  }

  private _setInterestIfApplicable() {
    let params = this.activatedRoute.snapshot.queryParamMap;
    if (params) {
      this.query.user = params.get('user');
      this.query.email = params.get('email');
      this.query.username = params.get('username');
      this.query.uuid_of_notification = params.get('uuid_of_notification');
      this.query.career = params.get('career');
      this.query.career_slug = params.get('career_slug');
      this.query.event = params.get('event');
      this.query.event_slug = params.get('event_slug');
      this.query.initaitve = params.get('initaitve');
      this.query.initiative_slug = params.get('initiative_slug');
      this.query.opportunity = params.get('opportunity');
      this.query.opportunity_slug = params.get('opportunity_slug');
      this.query.source = params.get('source');
      this.query.source_slug = params.get('source_slug');
      this.query.event_type = params.get('event_type');
      this.query.content = params.get('content');
      this.query.item_type = params.get('item_type');
      this.query.item = params.get('item');
      this.query.item_slug = params.get('item_slug');
      this.query.open_live = params.get('open_live');
      this.query.open_refer = params.get('open_refer');

      if (
        params.get('item_type') &&
        (params.get('uuid_of_notification') || ((params.get('user') || params.get('username') || params.get('email')))) &&
        (params.get('item') || params.get('item_slug')) &&
        (params.get('event_type') || params.get('event_slug')) &&
        (params.get('source') || params.get('source_slug'))
      ) {
        this.interestService.sendInterest(this.query).subscribe();
      } else {
        // check for backwards compatibility
        if (!params.get('query_type') && !params.get('function_type') && !params.get('item_type')) {
          if (params.get('career')) {
            this.query.item_type = params.get('career');
            this.query.item_slug = params.get('career_slug');
          }
          if (params.get('offer')) {
            this.query.item_type = params.get('offer');
            this.query.item_type = params.get('offer_slug');
          }
          if (params.get('listing')) {
            this.query.item = params.get('listing');
            this.query.item_type = 'career';
            this.query.item_slug = params.get('listing_slug');
          }
          if (this.query.item_type) {
            this.interestService.sendInterestBackwardsCompatible(this.query).subscribe();
          }
        }
      }
    }
  }

  ngOnDestroy() {
    if (this._trackerSubsription) {
      PubSub.unsubscribe(this._trackerSubsription);
    }

    if (this._routerEventsSubscription) {
      this._routerEventsSubscription.unsubscribe();
    }
  }

  private _subscribeToTokenExpiredEvent() {
    PubSub.subscribe(WEB_APP_EVENTS.TOKEN_EXPIRED, () => {
      this.authService.user$.next(null);
      this.authService.isAuthenticated$.next(false);
      this.profileService.user$.next(null);
      this.profileService.userProfile$.next(null);
    })
  }

  private _subscribeToRouterEvents() {
    this._routerEventsSubscription = this.router.events.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.isPageLoading = true;
          break;
        }

        case event instanceof NavigationEnd: {
          this.isPageLoading = false;
          this.modalService.dismissAll();
          // update page enter tracker
          this._onPageAnalyticsUpdate();
          break;
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.isPageLoading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  private _getIpAddress() {
    this.sharedHelperService.getIPAddress();
  }

  /**
   * Logout
   */
  onLogout() {
    this.authService.removeUserToken();
    this.router.navigate(['/login']);
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showOverlay = true;
    }
    if (event instanceof NavigationEnd) {
      this.showOverlay = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }

  /**
   * Subscribes to tacker info
   */
  private _subscribeToTrackerInfo() {
    let loginSubscription = PubSub.subscribe(WEB_APP_EVENTS.LOGIN_SUCCESS, (eventName, data) => {
      this.authService.getUserProfile();
    })

    this._trackerSubsription = PubSub.subscribe(WEB_APP_EVENTS.GET_USER_TRACKER_INFO, (eventName, data) => {
      this.isGoogleProfile = data ? data.gp : false;
      this.profileService.getUserProfileTracker().subscribe((response: any) => {
        this.profileService.profileTracker$.next(response.results);

        // choose stage and action based on profile tracker
        this._setUserStateByTracker();
      });
    })
  }

  /**
   * Sets user stage by tracker information
   */
  private _setUserStateByTracker() {
    let trackerInfo = this.profileService.profileTracker$.value;
    // Tracker doesnt exist - new user - take to welcome screen and create new tracker information
    if (!trackerInfo) {
      this._initUserTracker();
      return;
    }

    // Check if user has completed the onboarding or is still in-progress
    if (trackerInfo) {
      let userOnboardingStage = this._getTrackerStage(trackerInfo, this.sharedService.onboardingTrackerStages$.value);
      if (userOnboardingStage && userOnboardingStage.stage === PROFILE_STAGES.FINAL_CONFIRMATION && userOnboardingStage.status === PROFILE_TRACKER_STAGE_STATUS.COMPLETED) {
        // User has completed onboarding - redirect to page from which login was clicked
        if (this.navbarService.isAuthScreenActive$.value) {
          let url = this.postLoginRedirectUrl ? this.postLoginRedirectUrl : '/feed';
          this.router.navigate([url], {
            queryParams: {
              action: this.postLoginAction,
            },
            queryParamsHandling: 'merge'
          });
        }
      } else {
        // if org user, make a new function to handle org user cases

        // partial onboarding completed
        this._userOnboardingIncompleteHandler();
      }
    }
  }


  /**
   * Detects if user should go to onboarding / email verification / phone verification
   */
  private _userOnboardingIncompleteHandler() {
    this.authService.user$.subscribe(
      (res) => {
        const currentRoute = this.router.url
        // Bypassing phone number check for profiles where email is verified already
        if (res && res.phone_number && !res.is_phone_number_verified && !res.is_email_verified) {
          if (currentRoute !== '/change-phone') {
            this.router.navigate(['/verify-phone-number'])
          }
          return;
        }
        if (res && res.is_email_verified == false) {
          if (currentRoute !== '/change-email') {
            this.router.navigate(['/verify-email'])
          }
        } else if (res && res.is_email_verified == true) {
          // if org user (check through query params), redirect to org landing
          if(res && res.organisations && res.organisations.length > 0 && !this.activatedRoute.snapshot.queryParams['org-switch']) {
            if (location.href.indexOf('/organisation/landing') === -1) {
              this.router.navigate(['/organisation/landing']);
            }
            return;
          }
          if (!this._isUserOnboardingCompleted()) {
            this.router.navigate(['/welcome'], { queryParams: { gp: this.isGoogleProfile, 'org-switch': this.activatedRoute.snapshot.queryParams['org-switch'] } });
            if (this.activatedRoute.snapshot.url.toString().indexOf('/welcome') === -1) {
              this.toastr.show('Please complete these steps to enable us to approve your membership and better understand how we can support you', 'Welcome to Movemeback!');
            }
            return false;
          }
        }
      },
    )
  }

  private _isUserOnboardingCompleted(): boolean {
    let trackerInfo = this.profileService.profileTracker$.value;

    // Tracker doesnt exist - new user - take to welcome screen and create new tracker information
    if (!trackerInfo) {
      return false;
    }

    // Check if user has completed the onboarding or is still in-progress
    if (trackerInfo) {
      let userOnboardingStage = this._getTrackerStage(trackerInfo, this.sharedService.onboardingTrackerStages$.value);
      if (userOnboardingStage && userOnboardingStage.stage === PROFILE_STAGES.FINAL_CONFIRMATION && userOnboardingStage.status === PROFILE_TRACKER_STAGE_STATUS.COMPLETED) {
        return true;
      } else {
        // partial onboarding completed
        return false;
      }
    }
  }

  /**
   * Find the tracker stage by stage id (returns last tracker)
   */
  private _getTrackerStage(trackerInfo: ProfileTracker[], trackerStages: any[]) {
    return trackerInfo[trackerInfo.length - 1];
  }

  /**
   * Initialize user tracker
   */
  private _initUserTracker() {
    this.profileService.setUserProfileTracker().subscribe((response) => {
    }, (err) => {
    });
  }

  private _setNavigationCookie(url: string) {
    let currentValue: number = this._getCookie(url) !== '' ? parseInt(this._getCookie(url)) : 0;
    if (currentValue >= ALLOWED_ANONYMOUS_USER_RELOADS) {
      // show register modal
      this.sharedHelperService.openRegisterModal(null, null, 'Why not become a Member?');
    }
    // set the cookie with max age for expiry
    document.cookie = `${url}=${++currentValue}; max-age=${COOKIE_EXPIRY_DAYS * 24 * 60 * 60};`;
  }

  private _getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  private _onPageAnalyticsUpdate() {
    // send call again if user remains on same page every 20 seconds
    if (!this._pageEntryAnalyticsQuickPingSubscription) {
      this._pageEntryAnalyticsQuickPingSubscription = setInterval(() => {
        if (this._analyticsTimerCount * 1000 >= TIMER_SETTINGS.SLOW_PING_AFTER) {
          // clear the quick subscription and start slow ping
          clearInterval(this._pageEntryAnalyticsQuickPingSubscription);

          this._startSlowPingAnalytics();
        }
        this._analyticsTimerCount++;
        this._sendPageEntryAnalytics()
      }, TIMER_SETTINGS.ANALYTICS_TIMER);
    }

    this._sendPageEntryAnalytics();
  }

  private _startSlowPingAnalytics() {
    this._pageEntryAnalyticsSlowPingSubscription = setInterval(() => {
      if (this._analyticsTimerCount * 1000 >= TIMER_SETTINGS.USER_AWAY_PING_AFTER) {
        // clear the quick subscription and start slow ping
        clearInterval(this._pageEntryAnalyticsSlowPingSubscription);
        this._startUserAwayPingAnalytics();
      }
      this._analyticsTimerCount++;
      this._sendPageEntryAnalytics()
    }, TIMER_SETTINGS.SLOW_PING_TIMER);
  }

  private _startUserAwayPingAnalytics() {
    this._pageEntryAnalyticsAwayPingSubscription = setInterval(() => {
      if (this._analyticsTimerCount * 1000 >= TIMER_SETTINGS.IDLE_PING_AFTER) {
        // clear the quick subscription and start slow ping
        clearInterval(this._pageEntryAnalyticsAwayPingSubscription);
        this._startIdlePingAnalytics();
      }
      this._analyticsTimerCount++;
      this._sendPageEntryAnalytics()
    }, TIMER_SETTINGS.USER_AWAY_PING_TIMER);
  }

  private _startIdlePingAnalytics() {
    this._pageEntryAnalyticsIdlePingSubscription = setInterval(() => {
      this._analyticsTimerCount++;
      this._sendPageEntryAnalytics()
    }, TIMER_SETTINGS.IDLE_PING_TIMER);
  }

  private _sendPageEntryAnalytics() {
    let timerInfo = this._analyticsTimerCount !== 0 ? this._analyticsTimerCount * TIMER_SETTINGS.ANALYTICS_TIMER / 1000 : 0;

    const data = {
      object_uuid: '',
      name: document.title,
      slug: window.location.pathname,
      area: '',
      type: this._analyticsTimerCount !== 0 ? 'Secondary' : 'Initial',
      timer: timerInfo
    };

    this.userTrackersService.onPageEnter(data).subscribe();
  }
}
