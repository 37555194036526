import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mmb-web-app-card-actions',
  templateUrl: './card-actions.component.html',
  styleUrls: ['./card-actions.component.scss']
})
export class CardActionsComponent implements OnInit {
  @Output() toggleCommentSection: EventEmitter<any> = new EventEmitter<any>();
  @Input() comments: any[];
  isCommentSectionEnabled: boolean;

  constructor() { }

  ngOnInit() {
  }

  onToggleCommentSection() {
    this.toggleCommentSection.emit();
  }

}
