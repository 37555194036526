import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mmb-web-app-placeholder-no-responses',
  templateUrl: './placeholder-no-responses.component.html',
  styleUrls: ['./placeholder-no-responses.component.scss']
})
export class PlaceholderNoResponsesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
