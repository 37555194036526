import { Injectable } from '@angular/core';
import { environment } from 'projects/mmb-web-app/src/environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { CareerApplicationGet, CareerApplication } from '../types/career-application.model';
import { Career } from '../../shared/types/career.model';
import { UntypedFormGroup } from '@angular/forms';
import { UserProfile } from '../types/user-profile.model';

@Injectable({
    providedIn: 'root',
})
export class ApplyCareerService {
    selectedCareerDetails: Career;
    docsUploadStepData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    questionsStepData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    professionalTitleStepData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    currentCompensationStepData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    targetCompensationStepData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    currentLocationData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    targetLocationData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    isSubmittingApplication$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    userProfile$: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);

    private userProfile: UserProfile;

    constructor(private http: HttpClient) { }

    /**
     * Sets question step data
     */
    setQuestionsStepData(value: any) {
        this.questionsStepData$.next(value);
    }

    /**
     * Sets doc upload step data
     * @param value new value
     */
    setDocsUploadStepData(value: any) {
        if (value) {
            this.docsUploadStepData$.next({ ...value, ...this.docsUploadStepData$.value });
        }
    }

    /**
     * sets professional title information step data
     * @param value new value
     */
    setProfessionalTitleStepData(value: UntypedFormGroup) {
        this.professionalTitleStepData$.next(value);
    }

    /**
     * sets compensation information step data
     * @param value new value
     */
    setCurrentCompensationStepData(value: UntypedFormGroup) {
        this.currentCompensationStepData$.next(value);
    }

    /**
     * sets compensation information step data
     * @param value new value
     */
    setTargetCompensationStepData(value: UntypedFormGroup) {
        this.targetCompensationStepData$.next(value);
    }

    /**
     * sets location information step data
     * @param value new value
     */
    setCurrentLocationData(value: UntypedFormGroup) {
        this.currentLocationData$.next(value);
    }

    /**
     * sets location information step data
     * @param value new value
     */
    setTargetLocationData(value: UntypedFormGroup) {
        this.targetLocationData$.next(value);
    }

    /**
     * Gets pending application of user (if any)
     * @param offerSlug slug of career
     * @param userId logged in user id 
     */
    getPendingApplication(offerSlug: string): Observable<CareerApplicationGet> {
        let url = `${environment.domain}${environment.api.career.application}?offer__slug=${offerSlug}`;

        return this.http.get(url).pipe(
            map((response: HttpResponse<any>): CareerApplicationGet => {
                return (response as any).objects && (response as any).objects[0] ? (response as any).objects[0] : of();
            })
        );
    }

    /**
     * Gets pending application of user (if any)
     * @param offerSlug slug of career
     * @param userId logged in user id 
     */
     getPendingApplicationByCareerId(careerId: number): Observable<CareerApplicationGet> {
        let url = `${environment.domain}${environment.api.career.application}?career=${careerId}&response_type=full`;

        return this.http.get(url).pipe(
            map((response: HttpResponse<any>): CareerApplicationGet => {
                return (response as any).results && (response as any).results[0] ? (response as any).results[0] : of();
            })
        );
    }

    /**
     * Gets application of user (if any)
     * @param offerSlug slug of career
     * @param userId logged in user id
     */
    getApplication(slug: string): Observable<CareerApplicationGet> {
        let url = `${environment.domain}${environment.api.career.application}${slug}?response_type=full`;

        return this.http.get(url).pipe(
            map((response: HttpResponse<any>): CareerApplicationGet => {
                return (response as any);
            })
        );
    }

    /**
     * Save draft functionality
     * @param application career application
     */
    saveDraft(application: CareerApplication): Observable<CareerApplicationGet> {
        // if (application.id) {
        //     return this._saveNewDraft(application);
        // } else {
        return this._saveExistingDraft(application);
        // }
    }

    /**
     * Remove CV from application
     */
    removeCv(uuid: string) {
        let url = `${environment.domain}${environment.api.career.removeCv}${uuid}/`;
        return this.http.put(url, {});
    }

     /**
     * Remove CL from application
     */
     removeCoverLetter(uuid: string) {
        let url = `${environment.domain}${environment.api.career.removeCl}${uuid}/`;
        return this.http.put(url, {});
    }

    /**
     * Updates user's profile
     */
    updateUserCredentials(profileUpdates: any, uuid: string) {
        let url = `${environment.domain}${environment.api.career.credentials}${uuid}/`;

        return this.http.put(url, profileUpdates).pipe(
            map((response: HttpResponse<any>): CareerApplicationGet => {
                return response as any;
            })
        );
    }

    /**
     * Updates user's profile location
     */
    updateUserLocation(profileUpdates: any, uuid: string) {
        let url = `${environment.domain}${environment.api.user.user}${uuid}`;

        return this.http.put(url, profileUpdates).pipe(
            map((response: HttpResponse<any>): CareerApplicationGet => {
                return response as any;
            })
        );
    }

    /**
     * Submits application
     */
    submitApplication(application: CareerApplication) {
        let url = `${environment.domain}${environment.api.career.application}`;

        return this.http.post(url, application).pipe(
            map((response: HttpResponse<any>): CareerApplicationGet => {
                return response as any;
            })
        );
    }

    /**
     * Submits application
     */
    updateApplication(application: CareerApplication, uuid: string) {
        let url = `${environment.domain}${environment.api.career.application}${uuid}/`;

        return this.http.put(url, application).pipe(
            map((response: HttpResponse<any>): CareerApplicationGet => {
                return response as any;
            })
        );
    }

    /**
     * Gets user profile
     */
    getUserProfile(): Observable<UserProfile> {
        let url = `${environment.domain}${environment.api.user.profile}?response_type=full`;
        return this.http.get(url).pipe(
            map((response: any) => {
                this.userProfile = response.results[0];
                this.userProfile$.next(this.userProfile);
                return response.results[0];
            })
        );
    }

    /**
     * saves new draft
     * @param application career application
     */
    private _saveNewDraft(application: CareerApplication): Observable<CareerApplicationGet> {
        let url = `${environment.domain}${environment.api.career.application}`;

        return this.http.post(url, application).pipe(
            map((response: HttpResponse<any>): CareerApplicationGet => {
                return response as any;
            })
        );
    }

    /**
     * updates existing draft
     * @param application career application
     */
    private _saveExistingDraft(application: CareerApplication): Observable<CareerApplicationGet> {
        let url = `${environment.domain}${environment.api.career.application}${application.uuid}/`;

        return this.http.put(url, application).pipe(
            map((response: HttpResponse<any>): CareerApplicationGet => {
                return response as any;
            })
        );
    }
}
