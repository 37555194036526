import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SharedHelperService } from './shared-helper.service';

@Injectable({
  providedIn: 'root'
})
export class InterestService {

  constructor(private http: HttpClient, private sharedHelperService: SharedHelperService) { }

  sendInterest(query: any) {
    let url: string = this._getUrl(query);

    return this.http.post(url,
      {
        uuid_of_notification: query.uuid_of_notification,
        user: query.user,
        email: query.email,
        username: query.username,
        item: query.item,
        item_type: query.item_type,
        item_slug: query.item_slug,
        event_type: query.event_type,
        event_slug: query.event_slug,
        source: query.source,
        source_slug: query.source_slug,
        open_live: query.open_live,
        open_refer: query.open_refer,
        user_agent: window.navigator.userAgent,
        ip_address: this.sharedHelperService.ipAddress$.value,
      });
  }

  sendInterestBackwardsCompatible(query: any) {
    let url: string = this._getUrl(query);
    
    return this.http.post(url,
      {
        item: query.item,
        item_slug: query.item_slug,
        user: query.user,
        email: query.email,
        username: query.username,
        event_type: query.event_type,
        event_slug: query.event_slug,
        source: query.source,
        source_slug: query.source_slug,
        user_agent: window.navigator.userAgent,
        ip_address: this.sharedHelperService.ipAddress$.value,
        uuid_of_notification: query.uuid_of_notification
      });
  }

  private _getUrl(query) {
    let url: string = '';
    if (query.item_type === 'career') {
      url = `${environment.domain}${environment.api.interest.career}`;
    }

    if (query.item_type === 'event') {
      url = `${environment.domain}${environment.api.interest.event}`;
    }

    if (query.item_type === 'experience') {
      url = `${environment.domain}${environment.api.interest.initiative}`;
    }

    if (query.item_type === 'opportunity') {
      url = `${environment.domain}${environment.api.interest.opportunity}`;
    }
    return url;
  }
}
