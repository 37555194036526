import { animate, state, style, transition, trigger } from '@angular/animations';

export const fadeAnimation = trigger('fadeInOut', [
    state('void', style({
      opacity: 0,
      height:'0px'
    })),
    state('*', style({
      opacity: 1,
      height: '*'
    })),
    transition('void <=> *', animate('200ms ease-in-out'))
]);