import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mmb-web-app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Input() image: string = 'assets/images/avatars/not-found.png';

  constructor() { }

  ngOnInit() {
    let user = JSON.parse(<any>localStorage.getItem('user'));
    if(user.profile_image)
      this.image = user.profile_image;
  }

}
