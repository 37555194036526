import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../profile/types/user.model';
import { SharedHelperService } from './shared-helper.service';

export const PRODUCTION_DOMAIN = 'movemeback.com';
export const DEVELOPMENT_HOST = 'https://analytics.african100.com/api/v2';
export const PRODUCTION_HOST = 'https://analytics.movemeback.com/api/v2';

@Injectable({
  providedIn: 'root'
})
export class UserTrackersService {
  user: User = JSON.parse(localStorage.getItem('user'));
  ipAddress: any;
  uniqueID = Date.now();
  trackedImpressions: any[] = [];

  constructor(private http: HttpClient, private sharedHelperService: SharedHelperService) { }


  onImpressions(data: any) {
    let host = window.location.host.indexOf(PRODUCTION_DOMAIN) !== -1 ? PRODUCTION_HOST : DEVELOPMENT_HOST;
    // check if impression has been tracked already?
    let impressionFound = this.trackedImpressions.find((item) => item.object_uuid === data.object_uuid);
    let pageSeoData: any = this.sharedHelperService.findSeoMetaBySlug(location.pathname);

    if (!impressionFound) {

      const submitData = {
        platform: 'Web',
        username: this.user ? this.user.username : 'Anonymous',
        user_id: this.user ? this.user.id : null,
        object_uuid: data.object_uuid,
        name: data.name,
        slug: data.slug,
        area: pageSeoData ? pageSeoData.area : '',
        type: pageSeoData ? pageSeoData.type : '',
        ip: this.sharedHelperService.ipAddress$.value,
        user_agent: window.navigator.userAgent,
        transaction_id: this.uniqueID,
        is_anonymous: this.user ? false : true,
        additional_info: data.additional_info
      };
      const url = `${host}/tracking/impression`;
      this.trackedImpressions.push(submitData);
      return this.http.post(url, submitData, { responseType: 'text' });
    }
    return of();
  }

  onPageEnter(data: any) {
    let host = window.location.host.indexOf(PRODUCTION_DOMAIN) !== -1 ? PRODUCTION_HOST : DEVELOPMENT_HOST;
    let pageSeoData: any = this.sharedHelperService.findSeoMetaBySlug(data.slug);

    const submitData = {
      platform: 'Web',
      username: this.user ? this.user.username : 'Anonymous',
      user_id: this.user ? this.user.id : null,
      object_uuid: data.object_uuid,
      name: data.name,
      slug: data.slug,
      area: pageSeoData ? pageSeoData.area : '',
      type: pageSeoData ? pageSeoData.type : '',
      ip: this.sharedHelperService.ipAddress$.value,
      user_agent: window.navigator.userAgent,
      transaction_id: this.uniqueID,
      is_anonymous: this.user ? false : true,
      timer: data.timer,
      additional_info: data.additional_info
    };
    const url = `${host}/tracking/page`;
    return this.http.post(url, submitData, { responseType: 'text' });
  }

  onAction(data: any) {
    let host = window.location.host.indexOf(PRODUCTION_DOMAIN) !== -1 ? PRODUCTION_HOST : DEVELOPMENT_HOST;
    let pageSeoData: any = this.sharedHelperService.findSeoMetaBySlug(data.slug);

    const submitData = {
      platform: 'Web',
      username: this.user ? this.user.username : 'Anonymous',
      user_id: this.user ? this.user.id : null,
      object_uuid: data.object_uuid,
      name: data.name,
      slug: data.slug,
      area: pageSeoData ? pageSeoData.area : '',
      type: pageSeoData ? pageSeoData.type : '',
      ip: this.sharedHelperService.ipAddress$.value,
      user_agent: window.navigator.userAgent,
      transaction_id: this.uniqueID,
      is_anonymous: this.user ? false : true,
      additional_info: data.additional_info
    };
    const url = `${host}/tracking/event`;
    return this.http.post(url, submitData, { responseType: 'text' });
  }
}
