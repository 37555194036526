import { environment } from 'projects/mmb-web-app/src/environments/environment';

export const Register_Initial_Title:string = 'Join Movemeback';

export const Register_Static_Images:string[] = [
    '../../../assets/banners/register-bg-1.jpeg',
    '../../../assets/banners/register-bg-2.jpeg',
];

export const Register_Linked_Credentials = {
    clientId: environment.linkedIn.clientId,
    redirectUrl: `${window.location.origin}/login`,
    scope: environment.linkedIn.scope // To read basic user profile data and email
};

export interface OriginalName {
    firstName?: string,
    lastName?: string
}