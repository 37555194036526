import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { ForumService } from '../forum.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CK_EDITOR_SETTINGS } from '../../shared/types/_ck-editor-settings.const';

@Component({
  selector: 'mmb-web-app-modal-create-new-answer',
  templateUrl: './modal-create-new-answer.component.html',
  styleUrls: ['./modal-create-new-answer.component.scss']
})
export class ModalCreateNewAnswerComponent implements OnInit {
  @Input() questionId: number;
  @Output() answerSuccess: EventEmitter<any> = new EventEmitter<any>();

  Editor = ClassicEditor;
  editorConfig: any = CK_EDITOR_SETTINGS;
  answerForm = this.fb.group({
    answer: ['', Validators.required]
  })
  isSubmitting: boolean = false;
  constructor(public modal: NgbActiveModal, private toastr: ToastrService,
    private forumService: ForumService, private fb: UntypedFormBuilder) {
      this.editorConfig.placeholder = 'Write your answer here...';
     }

  ngOnInit() {
  }

  /**
   * Submit the answer
   */
  onSubmit() {
    if (this.isSubmitting) {
      return;
    }
    if (this.answerForm.controls['answer'].value.trim () === '') {
      this.toastr.error ('Please provide an answer before submitting...', 'Error');
      return;
    }
    this.isSubmitting = true;
    // Send the answer to api
    this.forumService.onSubmitAnswer (this.questionId, this.answerForm.controls['answer'].value)
        .subscribe ((response) => {
              this.answerSuccess.emit (response);
              this.isSubmitting = false;
            }, (err) => {
              this.isSubmitting = false;
              this.toastr.error ('Some error occurred while trying to save answer. Please retry or contact admin if issue persists', 'Error')
            }
        );
  }
}
